import React, {useState, useEffect} from 'react'
import MembershipBox from './MembershipBox'
import MembershipDescription from './MembershipDescription'
import MembershipIntro from './MembershipIntro'
import MembershipLogin from './MembershipLogin'
import MembershipSignup from './MembershipSignup'
import MembershipSignupSuccessMessage from './MembershipSignupSuccessMessage'
import MembershipLoginSuccessMessage from './MembershipLoginSuccessMessage'
import MembershipFooter from './MembershipFooter'
import useAuthentication from 'hooks/useAuthentication'
import useApi from 'hooks/useApi'

export const memberBoxStates = Object.freeze({
    intro: 'intro',
    login: 'login',
    loginSuccess: 'loginSuccess',
    // loginError: 'loginError',
    signUp: 'signUp',
    signUpSuccess: 'signUpSuccess',
    // signUpError: 'signUpError',
})

export default function Membership({source}) {
    const {
        signIn,
        signUp,
        getUserData,
        checkIsUserLogged,
        checkIsSigningIn,
        checkIsSigningUp,
        checkIsSignUpCompleted,
        getError,
        resetErrors,
    } = useAuthentication()
    let defaultStatus = memberBoxStates.intro;
    if (source === 'main') {
        defaultStatus = memberBoxStates.login;
    }
    defaultStatus = checkIsUserLogged() ? memberBoxStates.loginSuccess : defaultStatus;
    const {getFiscalCode, getComuniByName} = useApi()
    const [status, setStatus] = useState(defaultStatus)
    const [isCalculatingFC, setIsCalculatingFC] = useState(false)

    const userData = getUserData() || {}
    const isUserLogged = checkIsUserLogged()
    const isSignUpCompleted = checkIsSignUpCompleted()
    const isSigningIn = checkIsSigningIn()
    const isSigningUp = checkIsSigningUp()
    const error = getError()

    // console.info("userData", userData);

    const moveToStatus = statusKey => () => {
        setStatus(statusKey)
    }

    const calculateFiscalCode = async (
        name,
        surname,
        gender,
        birthDate,
        birthCity
    ) => {
        if (birthDate && surname && name && birthCity && gender) {
            try {
                setIsCalculatingFC(true)
                const body = {
                    firstName: name,
                    lastName: surname,
                    gender: gender,
                    birthDate: birthDate,
                    cadastralCode: birthCity.cadastralCode,
                }
                const fiscalCode = await getFiscalCode(body)
                setIsCalculatingFC(false)
                return fiscalCode
            } catch (error) {
                setIsCalculatingFC(false)
                return {status: false}
            }
        }
        return {status: false}
    }

    // transition from login to loginSuccess
    useEffect(() => {
        if (
            (status === memberBoxStates.intro || status === memberBoxStates.login) &&
            isUserLogged
        ) {
            setStatus(memberBoxStates.loginSuccess)
        }
    }, [isUserLogged, status])

    // transition from signUp to signUpSuccess
    useEffect(() => {
        if (status === memberBoxStates.signUp && isSignUpCompleted) {
            setStatus(memberBoxStates.signUpSuccess)
        }
    }, [isSignUpCompleted, status])

    // transition from logged to intro (after logout)
    useEffect(() => {
        if (
            (status === memberBoxStates.loginSuccess ||
                status === memberBoxStates.signUpSuccess) &&
            !isUserLogged
        ) {
           setStatus((source === 'main') ? memberBoxStates.login : memberBoxStates.intro)
        }
    }, [status, isUserLogged])

    switch (status) {
        case memberBoxStates.login:
            return (
                <MembershipBox>
                    <MembershipDescription/>
                    <MembershipLogin
                        isSigningIn={isSigningIn}
                        // hideForgottenPassword
                        onLogin={signIn}
                        onSignup={moveToStatus(memberBoxStates.signUp)}
                        error={error}
                        source={source}
                    />
                    <MembershipFooter source={source}/>
                </MembershipBox>
            )
        case memberBoxStates.signUp:
            return (
                <MembershipBox>
                    <MembershipDescription/>
                    <MembershipSignup
                        isSigningUp={isSigningUp}
                        onLogin={moveToStatus(memberBoxStates.login)}
                        onSignUp={signUp}
                        onCalculateFiscalCode={calculateFiscalCode}
                        isCalculatingFC={isCalculatingFC}
                        onGetComuniByName={getComuniByName}
                        error={error}
                        onResetError={resetErrors}
                        source={source}
                    />
                    <MembershipFooter source={source}/>
                </MembershipBox>
            )
        case memberBoxStates.loginSuccess:
            return <MembershipLoginSuccessMessage name={userData.firstName}/>
        case memberBoxStates.signUpSuccess:
            return <MembershipSignupSuccessMessage/>
        default:
            return (
                <MembershipBox>
                    <MembershipDescription/>
                    <MembershipIntro
                        onLogin={moveToStatus(memberBoxStates.login)}
                        onSignUp={moveToStatus(memberBoxStates.signUp)}
                    />
                </MembershipBox>
            )
    }
}
