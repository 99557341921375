export function addDays(date, days) {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const birthNationalityType = Object.freeze({
  CHILD: 'child',
  PARENT: 'parent',
})
