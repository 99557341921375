import {useContext} from 'react'
import {AppContext} from '../contexts/AppContext'
import * as adaApi from '../ada/specificUtils/api'
import * as hspApi from '../hsp/specificUtils/api'
import * as mainApi from '../main/specificUtils/api'
import * as docentiApi from '../docenti/specificUtils/api'
import * as linApi from '../lin/specificUtils/api'

const useApi = () => {
    const [state] = useContext(AppContext)

    if (state.division === '3') {
        return adaApi
    }
    if (state.applicationType === 'hsp') {
        return hspApi
    }
    if (state.applicationType === 'docenti') {
        return docentiApi
    }
    if (state.applicationType === 'lin') {
        return linApi
    }
    return mainApi
}

export default useApi
