import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import {dietTypes} from './DietUtils'

const useStyles = makeStyles(theme => ({
  textField8: {
    width: '62.666667%',
    marginLeft: 0,
    marginRight: 0,
  },
  select: {
    width: 400,
  },
  menu: {
    width: 400,
  },
}))

export default function Diet(props) {
  const classes = useStyles()
  const {
    // handlers
    onChange,
    onFlagChange,
    // step3 state
    step3Diet,
    step3DietType,
    step3DietText,
  } = props
  // const errors = props.errors || {}
  return (
    <Grid container className="box-light">
      <Grid item sm={4} xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">
            Desideri segnalare una dieta del partecipante?
          </FormLabel>
          <RadioGroup
            name="diet"
            className={classes.group}
            value={step3Diet}
            onChange={onFlagChange('step3Diet')}>
            <FormControlLabel value="si" control={<Radio />} label="Sì" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>

      {step3Diet === 'si' && (
        <Grid item sm={8} xs={12}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Tipo di dieta </FormLabel>
            <TextField
              id="diet-type"
              select
              // label="Select"
              // className="select-draft"
              className={classes.select}
              value={step3DietType}
              onChange={onChange('step3DietType')}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              // helperText="Please select your currency"
              margin="normal">
              <MenuItem value={dietTypes.CELIACA}>Dieta celiaca</MenuItem>
              <MenuItem value={dietTypes.VEGETARIANA}>
                Dieta vegetariana
              </MenuItem>
              <MenuItem value={dietTypes.VEGANA}>Dieta vegana</MenuItem>
              <MenuItem value={dietTypes.ALTRO}>Altra dieta</MenuItem>
            </TextField>
          </FormControl>

          <React.Fragment>
            <FormLabel component="legend">
              Vuoi aggiungere altre note riguardo la dieta?
            </FormLabel>
            <TextField
              id="multiline-flexible"
              multiline
              rowsMax="3"
              value={step3DietText}
              className={classes.textField8}
              margin="normal"
              onChange={onChange('step3DietText')}
            />
          </React.Fragment>
        </Grid>
      )}
    </Grid>
  )
}

Diet.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFlagChange: PropTypes.func.isRequired,
  // errors: PropTypes.object,
  step3Diet: PropTypes.oneOf(['si', 'no', '']),
  step3DietType: PropTypes.oneOf([
    'celiaca',
    'vegetariana',
    'vegana',
    'altro',
    '',
  ]),
  step3DietText: PropTypes.string,
}
