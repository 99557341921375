import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from './MemebrshipInfoIcon'

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    display: 'inline',
    margin: theme.spacing(0, 1),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: 'white',
  },
  titleContainer: {
    backgroundColor: '#416991',
    padding: theme.spacing(1, 2),
    borderBottomRightRadius: theme.spacing(3),
    maxWidth: '450px',
  },
}))

function MembershipDescription() {
  const classes = useStyles()

  return (
    <div className={classes.titleContainer}>
      <Typography
        variant="h5"
        display="inline"
        component="span"
        className={classes.title}>
        GIOCAMONDOCLUB
        <Tooltip title="Sei già registrato? Effetua il login. Ricorda di inserire l’email del genitore che ha effettuato la registrazione. Oppure prosegui e registrati. Importante: la registrazione all’area Giocamondo Club è obbligatoria per completare la prenotazione.">
          <span className={classes.iconWrapper}>
            <InfoIcon style={{color: '#00B219'}} />
          </span>
        </Tooltip>
      </Typography>
    </div>
  )
}

export default MembershipDescription
