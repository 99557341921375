import React, {useContext} from 'react'
import Button from '@material-ui/core/Button'
import {AppContext} from 'contexts/AppContext'
import {PackageSelectorContext} from 'contexts/PackageSelectorContext'
import {SpendingContext} from 'contexts/SpendingContext'
import {UserContext} from 'contexts/UserContext'
import {PaymentContext} from 'contexts/PaymentContext'

const isDebug = process.env.NODE_ENV === 'development' || (window.location.hostname === 'shop.staging.giocamondo.it') || (window.location.hostname === 'shop-bis.staging.giocamondo.it');

const debugData = {
  step1ChildBirthCity: {
    cadastralCode: 'A462',
    cap: '63100',
    car: 'AP',
    id: 9246,
    istatCode: '044007',
    label: 'Ascoli Piceno',
    metropolitanCity: false,
    name: 'Ascoli Piceno',
    province: 'Ascoli Piceno',
    region: 'Marche',
    value: 9246,
    zone: 'Centro',
  },
  step1ChildBirthDate: new Date('2013-02-21'),
  step1ChildBirthProv: 'AP',
  step1ChildCAP: '63100',
  step1ChildFiscalCode: 'JNRRSS13B21A462K',
  step1ChildGender: 'M',
  step1ChildName: 'Rossi',
  step1ChildPhone: '555555555',
  step1ChildPhoneAlt: '555555555',
  step1ChildEmail: 'sviluppo@webeing.net',
  step1ChildProv: 'AP',
  step1ChildRegion: 'Marche',
  step1ChildResidenceCity: 'Ascoli Piceno',
  step1ChildStreetAddress: 'Via senzanome 1',
  step1ChildResidenceCityObj: {
    cadastralCode: 'A462',
    cap: '63100',
    car: 'AP',
    id: 9246,
    istatCode: '044007',
    label: 'Ascoli Piceno',
    metropolitanCity: false,
    name: 'Ascoli Piceno',
    province: 'Ascoli Piceno',
    region: 'Marche',
    value: 9246,
    zone: 'Centro',
  },
  step1ChildSurname: 'Junior',
  // step1Confirmed: false,
  // step1DepartureServiceIds: "4757,4758",
  // step1DepartureServiceItems: null,
  // step1Nation: "GRAN BRETAGNA",
  // step1Package: 92,
  // step1PackageGroup: "BRIHF",
  step1ParentBirthCity: {
    cadastralCode: 'A462',
    cap: '63100',
    car: 'AP',
    id: 9246,
    istatCode: '044007',
    label: 'Ascoli Piceno',
    metropolitanCity: false,
    name: 'Ascoli Piceno',
    province: 'Ascoli Piceno',
    region: 'Marche',
    value: 9246,
    zone: 'Centro',
  },
  step1ParentBirthDate: new Date('1979-03-09'),
  step1ParentBirthProv: 'AP',
  step1ParentCAP: '63100',
  step1ParentEmail: 'sviluppo@webeing.net',
  step1ParentFiscalCode: 'MRARSS79C09A462Z',
  step1ParentGender: 'M',
  step1ParentName: 'Rossi',
  step1ParentPhone: '5555555555',
  step1ParentPhoneAlt: '5555555555',
  step1ParentProv: 'AP',
  step1ParentRegion: 'Marche',
  step1ParentResidenceCity: 'Ascoli Piceno',
  step1ParentResidenceCityObj: {
    cadastralCode: 'A462',
    cap: '63100',
    car: 'AP',
    id: 9246,
    istatCode: '044007',
    label: 'Ascoli Piceno',
    metropolitanCity: false,
    name: 'Ascoli Piceno',
    province: 'Ascoli Piceno',
    region: 'Marche',
    value: 9246,
    zone: 'Centro',
  },
  step1ParentStreetAddress: 'Via senzanome 1',
  step1ParentSurname: 'Mario',
  step1SameResidence: true,
  // step1Structure: null,
  // step1Time: "",
  // step1Turn: 92,
  // step1country: null,
  // step1countrylabel: null,
  // step1pack: 0,
  // step1packlabel: null,
  step1ChildDocType: 'idCard',
  step1ChildDocNumber: 'AS12345CD',
  step1ChildDocEmission: new Date('2019-02-21'),
  step1ChildDocExpiration: new Date('2039-02-20'),
}

export default function DebugPanel() {
  const [appState, setAppState] = useContext(AppContext)
  const [packageState] = useContext(PackageSelectorContext)
  const [spendingState] = useContext(SpendingContext)
  const [userState] = useContext(UserContext)
  const [paymentState] = useContext(PaymentContext)
  function loadDefaultUsers() {
    if (isDebug) {
      // console.log('is debug', debugData)
      setAppState(appState => ({
        ...appState,
        ...debugData,
      }))
    }
  }

  if (!isDebug) {
    return null
  }

  function logStates() {
    console.log({
      appState,
      packageState,
      spendingState,
      userState,
      paymentState,
    })
  }

  return (
    <React.Fragment>
      <Button onClick={logStates}>LOG STATES</Button>
      <Button onClick={() => loadDefaultUsers()}>Popola anagrafica</Button>
    </React.Fragment>
  )
}
