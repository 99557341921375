import React, {useState, useContext} from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Loader'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import {makeStyles} from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {participationsLookup} from '../../main/specificUtils/configs'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useEffect } from "react";
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import {AppContext} from 'contexts/AppContext'

// PayPal
const currency = "EUR";
const style = {"layout":"vertical"};
const ButtonWrapper = ({
  currency,
  showSpinner,
  amount,
  onPaypalAnswered,
  onPaypalPaidOrder,
  onMarkAsPaymentError,
  paypalMin,
  paypalMax,
  disableMyBank
}) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
      dispatch({
          type: "resetOptions",
          value: {
              ...options,
              currency: currency,
          },
      });
  }, [currency, showSpinner]);


  return (<>
          { (showSpinner && isPending) && <Loader /> }
          { /* una volta definito con chiarezza quali metodi di pagamento
               dovranno essere utilizzati sistemare questa sezione evitando
               di ripetere lo stesso codice di creazione e cattura ordine */ }
          { /* fino a nuovo ordine inibito */ false && (paypalMin <= amount && amount <= paypalMax ) &&
          <PayPalButtons
              fundingSource='paypal'
              style={style}
              disabled={false}
              createOrder={(data, actions) => {
                  return actions.order
                      .create({
                          purchase_units: [
                              {
                                  amount: {
                                      currency_code: currency,
                                      value: amount,
                                  },
                              },
                          ],
                      })
                      // .then((orderId) => {
                      //     // Your code here after create the order
                      //     return orderId;
                      // });
              }}
              onApprove={function (data, actions) {
                  return actions.order.capture().then(function () {
                      onPaypalAnswered(true)
                  }).then(function() {
                      return onPaypalPaidOrder(data.orderID)
                  });
              }}
              onError={err => {
                console.log({onError: err})
                onPaypalAnswered(true)
                onMarkAsPaymentError(err)
              }}
              onCancel={data => console.log({onCancel: data})}
          />}
          { /* fino a nuovo ordine inibito */ false && (paypalMin <= amount && amount <= paypalMax ) &&
          <PayPalButtons
              fundingSource='paylater'
              style={style}
              disabled={false}
              createOrder={(data, actions) => {
                  return actions.order
                      .create({
                          purchase_units: [
                              {
                                  amount: {
                                      currency_code: currency,
                                      value: amount,
                                  },
                              },
                          ],
                      })
                      // .then((orderId) => {
                      //     // Your code here after create the order
                      //     return orderId;
                      // });
              }}
              onApprove={function (data, actions) {
                  return actions.order.capture().then(function () {
                      onPaypalAnswered(true)
                  }).then(function() {
                      return onPaypalPaidOrder(data.orderID)
                  });
              }}
              onError={err => {
                console.log({onError: err})
                onPaypalAnswered(true)
                onMarkAsPaymentError(err)
              }}
              onCancel={data => console.log({onCancel: data})}
          />}
          { !disableMyBank &&
          <PayPalButtons
              fundingSource='mybank'
              style={style}
              disabled={false}
              createOrder={(data, actions) => {
                  return actions.order
                      .create({
                          purchase_units: [
                              {
                                  amount: {
                                      currency_code: currency,
                                      value: amount,
                                  },
                              },
                          ],
                      })
                      // .then((orderId) => {
                      //     // Your code here after create the order
                      //     return orderId;
                      // });
              }}
              onApprove={function (data, actions) {
                  return actions.order.capture().then(function () {
                      onPaypalAnswered(true)
                  }).then(function() {
                      return onPaypalPaidOrder(data.orderID)
                  });
              }}
              onError={err => {
                console.log({onError: err})
                onPaypalAnswered(true)
                onMarkAsPaymentError(err)
              }}
              onCancel={data => console.log({onCancel: data})}
          />}
          <PayPalButtons
              fundingSource='card'
              style={style}
              disabled={false}
              createOrder={(data, actions) => {
                  return actions.order
                      .create({
                          purchase_units: [
                              {
                                  amount: {
                                      currency_code: currency,
                                      value: amount,
                                  },
                              },
                          ],
                      })
                      // .then((orderId) => {
                      //     // Your code here after create the order
                      //     return orderId;
                      // });
              }}
              onApprove={function (data, actions) {
                  return actions.order.capture().then(function () {
                      onPaypalAnswered(true)
                  }).then(function() {
                      return onPaypalPaidOrder(data.orderID)
                  });
              }}
              onError={err => {
                console.log({onError: err})
                onPaypalAnswered(true)
                onMarkAsPaymentError(err)
              }}
              onCancel={data => console.log({onCancel: data})}
          />
      </>
  );
}

const useStyles = makeStyles(theme => ({
  payButton: {
    margin: theme.spacing(2),
  },
  bankTransferDesc: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  otherPayment: {
    margin: theme.spacing(1),
  },
  expandedDetail: {
    display: 'flex',
    flexDirection: 'column',
    // marginBottom: theme.spacing(1),
  },
  expansionPanel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export default function Payment(props) {
  const [appState] = useContext(AppContext)
  const classes = useStyles()
  // const instance = useRef(null)
  const [loading, setLoading] = useState(true)

  const {
    //state,
    //billingAddress,
    amount,
    // email,
    payError,
    paying,
    paid,
    onFinalizeBankTransferOrder,
    onBankTransfer,
    bankTransfer,
    //campi inseriti da Agata
    // paypalTransfer,
    // onPaypalTransfer,
    step2Participation,
    division,
    paypalClientId,
    onPaypalPaidOrder,
    onMarkAsPaymentError,
    paypalAnswered,
    onPaypalAnswered,
  } = props
  const [expanded, setExpanded] = useState(bankTransfer)

  //const {step2Participation} = state
  const handleChange = event => {
    const {checked} = event.target
    if (checked) {
      setExpanded(true)
    }
    onBankTransfer(checked)
    // onPaypalTransfer(false)
  }

  const handleExpansion = (event, expandedState) => {
    setExpanded(expandedState)
  }

  // const handleChangePayPal = event => {
  //   onBankTransfer(false)
  //   onPaypalTransfer(event.target.checked)
  // }

  // if (!clientToken) {
  //   return <Loader />
  // }

  // type of text
  let textBankTransfer = {
    iban: '',
    bic: '',
  }

  const inpsPractice = (
    step2Participation === participationsLookup.INPSIEME ||
    step2Participation === participationsLookup.INPSNUOVO
  )

  if (division === '1') {
    // GSTUDY
    if (
      step2Participation === participationsLookup.INPSIEME ||
      step2Participation === participationsLookup.INPSNUOVO
    ) {
      textBankTransfer = {
        /*title: 'Bonifico ordinario (il tuo posto è bloccato, effettua il bonifico entro 5 giorni per confermarlo)',
        title:
        'Clicca qui per confermare e invia la prenotazione',*/
        title:
          'Bonifico ordinario',
        iban: 'IT23Y0306913507100000007395 (Banca Intesa San Paolo)',
        bic: 'BCITITMM',
      }
    } else if (step2Participation === participationsLookup.PRIVATO) {
      textBankTransfer = {
        /*title: 'Bonifico ordinario da effettuare (riceverai una mail con tutti gli estremi e le indicazioni per procedere al pagamento)',
        title:
        'Clicca qui per confermare e invia la prenotazione',*/
        title:
          'Bonifico ordinario',
        iban: 'IT23Y0306913507100000007395 (Banca Intesa San Paolo)',
        bic: 'BCITITMM',
      }
    } else if (
      step2Participation === participationsLookup.AZIENDA_CONVENZIONATA
    ) {
      textBankTransfer = {
        /*title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',
          title:
        'Clicca qui per confermare e invia la prenotazione',*/
        title:
          'Bonifico ordinario',
        iban: 'IT12V0311113501000000004631 (Ubi Banca)',
        bic: 'BLOPIT22',
      }
    }
  } else if (division === '2') {
    // LME
    if (
      step2Participation === participationsLookup.INPSIEME ||
      step2Participation === participationsLookup.INPSNUOVO
    ) {
      textBankTransfer = {
        //title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',
        title:
          'Bonifico ordinario',
        iban: 'IT02F0306913507100000005144 (Banca Intesa San Paolo)',
        bic: 'BCITITMM',
      }
    } else if (step2Participation === participationsLookup.PRIVATO) {
      textBankTransfer = {
        //title: 'Bonifico ordinario da effettuare (riceverai una mail con tutti gli estremi e le indicazioni per procedere al pagamento)',
        title:
          'Bonifico ordinario ',
        iban: 'IT76O0103013500000000911744 (Banca Monte Paschi Siena)',
        bic: 'PASCITMMASP',
      }
    } else if (
      step2Participation === participationsLookup.AZIENDA_CONVENZIONATA
    ) {
      textBankTransfer = {
        //title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',
        title:
          'Bonifico ordinario',
        iban: 'IT76O0103013500000000911744 (Banca Monte Paschi Siena)',
        bic: 'PASCITMMASP',
      }
    }
  } else if (division === '3') {
    textBankTransfer = {
      /*title: 'Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)',*/
      title:
        'Bonifico ordinario',
      iban: 'IT23Y0306913507100000007395 (Banca Intesa San Paolo)',
      bic: 'BCITITMM',
    }
  }

  const paypalMin = 30
  let paypalMax = 500
  if (division === '2') paypalMax = 300

  const isMain = false //(appState.applicationType === 'main')
  return (
    <>
      {!paid && (
        <Grid container spacing={3} className="panel">
          {/*Il blocco del bonifico non dev'essere visualizzato se il form è il main*/}
          { !paypalAnswered && !isMain && ( /* ripensamento Giocamondo: visualizzare i bonifici anche per flusso INPS */
            <>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={10} className="payment-wrapper">
                <>
                  {/* <Paper variant="outlined"> */}
                  <ExpansionPanel
                    className={classes.expansionPanel}
                    expanded={expanded}
                    onChange={handleExpansion}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="additional-actions1-content"
                      id="additional-actions1-header">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={bankTransfer}
                            onChange={handleChange}
                            disabled={paying}
                            value="primary"
                            inputProps={{'aria-label': 'primary checkbox'}}
                          />
                        }
                        //label="Bonifico ordinario (riceverai tutti gli estremi e le indicazioni per procedere al pagamento)"
                        label={textBankTransfer.title}
                      />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expandedDetail}>
                      {/*
                      <Typography
                        variant="subtitle2"
                        display="block"
                        className={classes.bankTransferDesc}>
                        
                        {division === '2' || division === '3' ? (
                        <React.Fragment>
                        Riceverai gli estremi per effettuare il pagamento con l'email di riepilogo. Il pagamento dovrà essere effettuato entro 5 giorni dalla presente prenotazione
                        <br />
                        </React.Fragment>
                        ) : (
                        <React.Fragment>
                        Riceverai gli estremi per effettuare il pagamento con l'email di riepilogo. Il pagamento dovrà essere effettuato entro 5 giorni dalla presente prenotazione
                        </React.Fragment>)}
                      </Typography>
                      */}
                      <div align-items="center">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => onFinalizeBankTransferOrder()}
                          className={classes.payButton}
                          disabled={!bankTransfer || paying}>
                          {/*Conferma pagamento con Bonifico*/}
                          Invia Prenotazione
                        </Button>
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </>
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </>
          )}

          {!bankTransfer && !!paypalClientId && (
            <>
              <Grid item xs={12} sm={1}></Grid>
              <Grid
                item
                xs={12}
                sm={10}
                className="payment-wrapper"
                align-items="center">
                {!bankTransfer && !paypalAnswered && !isMain && (
                  <Typography variant="h6" className={classes.otherPayment}>
                    Oppure conferma immediatamente con:
                  </Typography>
                )}
                {isMain && <hr/>}
                <Paper variant="outlined">
                  <PayPalScriptProvider
                    options={{
                        "client-id": paypalClientId,
                        components: "buttons,funding-eligibility",
                        currency: currency,
                        'enable-funding': 'paylater',
                        commit: true,
                    }}
                  >
	            			<ButtonWrapper
                      currency={currency}
                      showSpinner={true}
                      amount={amount}
                      onPaypalAnswered={onPaypalAnswered}
                      onPaypalPaidOrder={onPaypalPaidOrder}
                      onMarkAsPaymentError={onMarkAsPaymentError}
                      paypalMin={paypalMin}
                      paypalMax={paypalMax}
                      disableMyBank={appState.productManagementFlags['formPrenotazione.disabilitaPayPalMyBank'].status}
                    />
			            </PayPalScriptProvider>
                  {paypalAnswered && <CircularProgress />}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
            </>
          )}
        </Grid>
      )}

      {/* @Agata questa è la parte che mostra l'eventuale errore, da stilizzare */}
      {/* {payError && (
        <div>{payError.message || 'Errore generico nel pagamento'}</div>
      )} */}

      {paid && !payError && (
        <div> Operazione di pagamento effettuata con successo </div>
      )}
    </>
  )
}

Payment.propTypes = {
  //state: PropTypes.object.isRequired,
  //billingAddress: PropTypes.object.isRequired,
  amount: PropTypes.string.isRequired,
  // email: PropTypes.string.isRequired,
  payError: PropTypes.any,
  paying: PropTypes.bool,
  paid: PropTypes.bool,
  onFinalizeBankTransferOrder: PropTypes.func.isRequired,
  onBankTransfer: PropTypes.func.isRequired,
  bankTransfer: PropTypes.bool,
  step2Participation: PropTypes.string,
  division: PropTypes.string,
  paypalClientId: PropTypes.string,
  onPaypalPaidOrder: PropTypes.func.isRequired,
  onMarkAsPaymentError: PropTypes.func.isRequired,
}
