import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MembershipActionFooter from './MembershipActionFooter'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import MaterialAutocomplete from 'components/MaterialAutocomplete'
import {Alert, AlertTitle} from '@material-ui/lab'
import PasswordComponent from './MembershipPasswordComponent'

function keyToLabel(key) {
  switch (key) {
    case 'firstName':
      return 'nome'
    case 'lastName':
      return 'cognome'
    case 'email1':
      return 'email'
    case 'fiscalCode':
      return 'codice fiscale'
    case 'birthDate':
      return 'data di nascita'
    case 'birthPlace':
      return 'luogo di nascita'
    case 'birthNation':
      return 'nazione di nascita'
    case 'gender':
      return 'sesso'
    case 'foreignBirthCity':
      return 'città di nascita estera'
    case 'mobilePhone':
      return 'cellulare'
    case 'password':
      return 'password'
    case 'privacyAccepted':
      return 'consenso del trattamento dei dati'
    case 'marketingAccepted':
      return 'consenso alle finalità commerciali'
    default:
      return key
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
  checkbox: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  flexItem: {
    display: 'flex',
  },
  inputWrapper: {
    marginRight: theme.spacing(2),
  },
  date: {
    marginRight: theme.spacing(2),
    flex: 1,
    border: '10px',
    backgroundColor: 'red',
  },
  radioContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  textUnderline: {
    textDecoration: 'underline',
  },
  fiscalCodeButton: {
    // backgroundColor: '#FC7200',
    // color: 'white',
    marginRight: theme.spacing(1),
    padding: theme.spacing(0, 2),
    // '&:hover': {
    //   backgroundColor: '#FC7200',
    //   color: 'white',
    // },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textAction: {
    color: '#FC7200',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
}))

export default function MembershipSignUp(props) {
  const {
    onLogin,
    onSignUp,
    isSigningUp = false,
    error,
    isCalculatingFC,
    onCalculateFiscalCode,
    onGetComuniByName,
    onResetError,
  } = props
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [password, setPassword] = useState('')
  const [repeatedPassword, setRepeatedPassword] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [gender, setGender] = useState('')
  const [fiscalCode, setFiscalCode] = useState('')
  const [nationality, setNationality] = useState('Italiana')
  const [nation, setNation] = useState('Italia')
  const [cityObj, setCityObj] = useState(null)
  const [city, setCity] = useState('')
  const [foreignCity, setForeignCity] = useState('')
  const [province, setProvince] = useState('')
  const [privacyConsent, setPrivacyConsent] = useState(false)
  const [marketing, setMarketing] = useState(null)
  const [birthDate, setBirthDate] = useState('')
  const [validationErrors, setValidationErrors] = useState([])
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(showPassword => !showPassword)
  }

  let errors
  let errorFields = []

  if (error) {
    const {data = {}} = error.response || {}
    if (data.code === 422) {
      errors = data.errors || {}
      errorFields = Object.keys(errors) || []
    }
  }

  const isFcCalculationEnabled =
    !isCalculatingFC &&
    nationality === 'Italiana' &&
    name &&
    name.trim().length > 0 &&
    surname &&
    surname.trim().length > 0 &&
    gender &&
    birthDate &&
    birthDate.trim().length > 0 &&
    cityObj

  const isSignupEnabled =
    !isSigningUp &&
    birthDate &&
    birthDate.trim().length > 0 &&
    email.trim().length > 0 &&
    name.trim().length > 0 &&
    surname.trim().length > 0 &&
    password.trim().length > 0 &&
    password === repeatedPassword &&
    phoneNumber.trim().length > 0 &&
    fiscalCode.trim().length > 0 &&
    city.trim().length > 0 &&
    ((nationality === 'Italiana' && province.trim().length > 0) ||
      (nationality === 'Estera' && nation.trim().length > 0)) &&
    privacyConsent &&
    marketing

  const handleFiscalCodeButtonPressed = async () => {
    try {
      const fcResult = await onCalculateFiscalCode(
        name,
        surname,
        gender,
        birthDate,
        cityObj
      )
      const {status, result} = fcResult
      if (status && result) {
        setFiscalCode(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function handleSignUp() {
    setValidationErrors([])
    let newValidationErrors = []
    if (email.indexOf('@') === -1) {
      newValidationErrors.push({
        key: 'email',
        value: 'il campo deve contenere un indirizzo email valido',
      })
    }
    if (password.length < 8) {
      newValidationErrors.push({
        key: 'password',
        value: 'la lunghezza deve essere di almeno 8 caratteri',
      })
    }
    if (fiscalCode.length < 16) {
      newValidationErrors.push({
        key: 'codice fiscale',
        value: 'il campo deve avere 16 cifre',
      })
    } else if (nationality === 'Italiana') {
      const fcResult = await onCalculateFiscalCode(
        name,
        surname,
        gender,
        birthDate,
        cityObj
      )
      const {status, result} = fcResult
      if (status && fiscalCode.substring(0, 15) !== result.substring(0, 15)) {
        newValidationErrors.push({
          key: 'codice fiscale',
          value:
            'i dati anagrafici non corrispondono al codice fiscale inserito, si prega di controllare',
        })
      }
    }
    if (newValidationErrors.length > 0) {
      onResetError()
      setValidationErrors(newValidationErrors)
      return
    }
    const userData = {
      firstName: name,
      lastName: surname,
      email1: email,
      gender,
      birthPlace: city,
      birthNation: nation,
      foreignBirthCity: foreignCity,
      birthDate,
      fiscalCode,
      mobilePhone: phoneNumber,
      privacyAccepted: privacyConsent,
      marketingAccepted: marketing,
    }
    onSignUp(userData, password, false)
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={12}>
          <Typography className={classes.textBold}>REGISTRATI</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            className={classes.inputWrapper}
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            onChange={event => {
              setName(event.target.value)
            }}
            value={name}
            placeholder="Nome*"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            className={classes.inputWrapper}
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            onChange={event => {
              setSurname(event.target.value)
            }}
            value={surname}
            placeholder="Cognome*"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl component="fieldset" className={classes.flexItem}>
            <RadioGroup
              aria-label="Sesso"
              name="gender1"
              row
              className={classes.radioContainer}
              value={gender}
              onChange={evt => setGender(evt.target.value)}>
              <FormControlLabel value="F" control={<Radio />} label="Donna" />
              <FormControlLabel value="M" control={<Radio />} label="Uomo" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="standard-select-currency"
            select
            label="Nazione di nascita"
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            className={classes.inputWrapper}
            value={nationality}
            onChange={event => {
              const {value} = event.target
              if (value === 'Estera') {
                setNation('')
                setCity('')
              } else {
                setNation('Italia')
                setForeignCity('')
              }
              setNationality(event.target.value)
            }}>
            <MenuItem value="Italiana">Italiana</MenuItem>
            <MenuItem value="Estera">Estera</MenuItem>
          </TextField>
        </Grid>
        {nationality === 'Estera' ? (
          <>
            <Grid item xs={12} sm={2}>
              <TextField
                className={classes.inputWrapper}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={event => {
                  setNation(event.target.value)
                }}
                value={nation}
                placeholder="Nazione*"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                className={classes.inputWrapper}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={event => {
                  setForeignCity(event.target.value)
                }}
                value={foreignCity}
                placeholder="Città*"
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={5}>
              <MaterialAutocomplete
                value={cityObj}
                placeholder={gender === 'F' ? 'Nata a*' : 'Nato a*'}
                onChange={value => {
                  setCityObj(value)
                  if (value) {
                    setProvince(value.car)
                    setCity(value.name)
                  }
                  console.log({value, cityObj})
                }}
                onGetOptions={onGetComuniByName}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                className={classes.inputWrapper}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={event => {
                  setProvince(event.target.value)
                }}
                value={province}
                placeholder="Prov*"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={3}>
          <TextField
            className={classes.inputWrapper}
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{shrink: true}}
            onChange={event => {
              setBirthDate(event.target.value)
            }}
            value={birthDate}
            label="Data di nascita*"
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={3} style={{alignSelf: 'flex-start'}}>
          <Button
            fullWidth
            className={classes.fiscalCodeButton}
            disabled={!isFcCalculationEnabled}
            variant="contained"
            onClick={handleFiscalCodeButtonPressed}>
            CALCOLA CF
            {isCalculatingFC && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            className={clsx(classes.inputWrapper, 'cf-field')}
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            onChange={event => {
              setFiscalCode(event.target.value)
            }}
            disabled={isCalculatingFC}
            value={fiscalCode}
            placeholder="Codice Fiscale*"
            helperText={
              'Attenzione ricontrolla il codice fiscale che sarà utilizzato in tutti i documenti a te intestati'
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.inputWrapper}
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            onChange={event => {
              setEmail(event.target.value)
            }}
            value={email}
            placeholder="Email*"
            type="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.inputWrapper}
            fullWidth
            InputProps={{
              disableUnderline: true,
            }}
            onChange={event => {
              setPhoneNumber(event.target.value)
            }}
            value={phoneNumber}
            placeholder="Cellulare*"
            type="tel"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PasswordComponent
            componentStyle={classes.inputWrapper}
            fullWidth
            onChange={setPassword}
            value={password}
            placeholder="Password*"
            error={
              password.length > 0 &&
              repeatedPassword.length > 0 &&
              password !== repeatedPassword
            }
            helperText={
              password.length > 0 &&
              repeatedPassword.length > 0 &&
              password !== repeatedPassword
                ? 'La password non corrisponde'
                : ''
            }
            showPassword={showPassword}
            onShowPasswordClicked={handleClickShowPassword}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PasswordComponent
            componentStyle={classes.inputWrapper}
            fullWidth
            onChange={setRepeatedPassword}
            value={repeatedPassword}
            placeholder="Ripeti password*"
            error={
              password.length > 0 &&
              repeatedPassword.length > 0 &&
              password !== repeatedPassword
            }
            helperText={
              password.length > 0 &&
              repeatedPassword.length > 0 &&
              password !== repeatedPassword
                ? 'La password non corrisponde'
                : ''
            }
            showPassword={showPassword}
            onShowPasswordClicked={handleClickShowPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <p>
            <small>
              Informativa estesa sulla
              <a
                href="https://www.iubenda.com/privacy-policy/411930"
                title="Informativa privacy"
                rel="noopener noreferrer"
                target="_blank">
                {' '}
                privacy
              </a>
              . Ai sensi e per gli effetti dell'art.4 DPR 445/2000, in relazione
              al rilascio di dichiarazioni mendaci, dichiaro che le informazioni
              rese nel presente form corrispondono a verità e accetto che queste
              vengano utilizzate unicamente per le finalità per le quali sono
              state acquisite (GDPR - Regolamento 2016/679). Per quanto
              riguarda i consensi da lei sottoscritti le comunichiamo che
              limiteremo le nostre attività sui vostri dati, per le finalità
              indicate nell’informativa, entro i limiti delle autorizzazioni che
              ci avete concesso e al fine di verificare i consensi sottoscritti
              può inviare una mail al seguente indirizzo:
              <a
                href="mailto:privacy@giocamondo.it"
                title="Mail per verificare i consensi sottoscritti">
                privacy@giocamondo.it
              </a>
              . L'accettazione è obbligatoria per poter procedere all'invio dei
              dati.
            </small>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={privacyConsent}
                  onChange={evt => setPrivacyConsent(evt.target.checked)}
                />
              }
              label="Dichiaro di aver preso visione della privacy policy"
            />
          </p>
        </Grid>

        <Grid item xs={12}>
          <p>
            <small>
              Consenti il trattamento dei Tuoi dati personali per il ricevimento
              della Newsletter aziendale, di comunicazioni relative a nostri
              servizi, prodotti, promozioni per finalità di marketing diretto?
              <a
                href="https://www.iubenda.com/privacy-policy/411930"
                title="Informativa privacy"
                rel="noopener noreferrer"
                target="_blank">
                {' '}
                Leggi qui{' '}
              </a>
              l'informativa estesa sulla privacy per maggiori dettagli.
            </small>
          </p>
          <RadioGroup
            aria-label="position"
            name="position"
            value={marketing}
            onChange={event => {
              setMarketing(event.target.value)
            }}
            row>
            <FormControlLabel
              value="si"
              control={<Radio color="primary" />}
              label="Acconsento"
              labelPlacement="end"
            />
            <FormControlLabel
              value="no"
              control={<Radio color="primary" />}
              label="Non Acconsento"
              labelPlacement="end"
            />
          </RadioGroup>
        </Grid>
        {(!!error || validationErrors.length > 0) && (
          <Grid item xs={12}>
            <Alert severity="error" className={classes.alert}>
              <AlertTitle>Errore nella richiesta di registrazione</AlertTitle>
              {errorFields.length > 0 || validationErrors.length > 0 ? (
                <>
                  <span>I seguenti campi presentano dei problemi:</span>
                  <ul>
                    {validationErrors.length > 0 &&
                      validationErrors.map(({key, value}) => (
                        <li>
                          {key}: {value}
                        </li>
                      ))}
                    {errorFields.length > 0 &&
                      errorFields.map(key => {
                        const label = keyToLabel(key)
                        return (
                          <li>
                            {label}: {errors[key].join(',')}
                          </li>
                        )
                      })}
                  </ul>

                  <div className={classes.root}>
                    <Typography component="span" variant="body2">
                      Se sei già registrato,
                      <a
                        id="inserimentoDati"
                        href="https://club.giocamondo.it/password-recovery"
                        target="_blank"
                        className={classes.link}
                        rel="noopener noreferrer">
                        <strong>
                          clicca qui per recuperare la tua password
                        </strong>{' '}
                        &nbsp;
                      </a>{' '}
                      e torna alla prenotazione.
                    </Typography>
                    <br />
                    <br />
                    <a
                      id="inserimentoDati"
                      href="#inserimentoDati"
                      className={classes.link}>
                      <Typography
                        className={`${classes.iconWrapper} ${classes.textAction}`}
                        component="span">
                        &darr;
                      </Typography>
                      <Typography component="span" variant="body2">
                        &nbsp; Oppure puoi comunque continuare la prenotazione
                        scorrendo sotto senza registrarti.
                      </Typography>
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <span>
                    Si è verificato un errore generico durante il tentativo di
                    registrazione. Si prega di riprovare.
                  </span>
                  <div className={classes.root}>
                    <a
                      id="inserimentoDati"
                      href="#inserimentoDati"
                      className={classes.link}>
                      <Typography
                        className={`${classes.iconWrapper} ${classes.textAction}`}
                        component="span">
                        &darr;
                      </Typography>
                      <Typography component="span" variant="body2">
                        Puoi comunque continuare la prenotazione scorrendo sotto
                        senza registrarti.
                      </Typography>
                    </a>
                  </div>
                </>
              )}
            </Alert>
          </Grid>
        )}
      </Grid>
      <MembershipActionFooter
        captionText="Hai gi&agrave; un account?"
        onPrimaryAction={handleSignUp}
        onSecondaryAction={onLogin}
        isPrimaryActionDisabled={!isSignupEnabled || isCalculatingFC}
        primaryActionText="REGISTRATI"
        secondaryActionText="Accedi ora"
        isPerformingPrimaryAction={isSigningUp}
      />
    </div>
  )
}

MembershipSignUp.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onSignUp: PropTypes.func.isRequired,
  isSigningUp: PropTypes.bool,
  error: PropTypes.object,
  onCalculateFiscalCode: PropTypes.func.isRequired,
  onGetComuniByName: PropTypes.func.isRequired,
  isCalculatingFC: PropTypes.bool,
  onResetError: PropTypes.func.isRequired,
}
