import React, {useContext} from 'react'
import SchoolInformationUI from './SchoolInformation'
import {AppContext} from '../../contexts/AppContext'

export default function SchoolInformation() {
  const [state, setState] = useContext(AppContext)
  const {
    errors,
    schoolName,
    schoolAddress,
    schoolClass,
    language1,
    languageLvl1,
    language2,
    languageLvl2,
    language3,
    languageLvl3,
    language4,
    languageLvl4,
    language5,
    languageLvl5,
  } = state
  const handleChange = label => event => {
    const {value} = event.target
    setState(state => ({...state, [label]: value}))
  }
  const handleCheckboxChange = label => event => {
    const {value, checked} = event.target
    if (checked) {
      setState(state => ({...state, [label]: value}))
    } else {
      setState(state => ({
        ...state,
        [label]: '',
        [`languageLvl${label.slice(-1)}`]: '',
      }))
    }
  }
  return (
    <SchoolInformationUI
      onChange={handleChange}
      onCheckboxChange={handleCheckboxChange}
      errors={errors}
      schoolName={schoolName}
      schoolAddress={schoolAddress}
      schoolClass={schoolClass}
      language1={language1}
      languageLvl1={languageLvl1}
      language2={language2}
      languageLvl2={languageLvl2}
      language3={language3}
      languageLvl3={languageLvl3}
      language4={language4}
      languageLvl4={languageLvl4}
      language5={language5}
      languageLvl5={languageLvl5}
    />
  )
}
