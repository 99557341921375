export const INIT_START = 'INIT_START'
export const INIT_COMPLETED = 'INIT_COMPLETED'
// export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
// export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
// export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'
export const SIGN_REQUEST = 'SIGN_REQUEST'
export const SIGN_SUCCESS = 'SIGN_SUCCESS'
export const SIGN_ERROR = 'SIGN_ERROR'
export const SIGN_OUT = 'SIGN_OUT'
export const RESET_ERROR = 'RESET_ERROR'

// sign type
export const SIGN_TYPE_SIGN_IN = 'SIGN_IN'
export const SIGN_TYPE_SIGN_UP = 'SIGN_UP'

export function startInit() {
  return {type: INIT_START}
}

export function finishInit(token, userData) {
  return {type: INIT_COMPLETED, token, userData}
}

// export function fetchUserRequest() {
//   return {type: FETCH_USER_REQUEST}
// }

// export function fetchUserSuccess(userData) {
//   return {type: FETCH_USER_SUCCESS, userData}
// }

// export function fetchUserError(error) {
//   return {type: FETCH_USER_ERROR, error}
// }

export function signInRequest() {
  return {
    type: SIGN_REQUEST,
    signType: SIGN_TYPE_SIGN_IN,
  }
}

export function signUpRequest(userData) {
  return {
    type: SIGN_REQUEST,
    signType: SIGN_TYPE_SIGN_UP,
    userData,
  }
}

export function signSuccess(token, userData) {
  return {
    type: SIGN_SUCCESS,
    token,
    userData,
  }
}

export function signError(error) {
  return {
    type: SIGN_ERROR,
    error,
  }
}

export function signOut() {
  return {type: SIGN_OUT}
}

export function resetError() {
  return {type: RESET_ERROR}
}
