import React, {useState} from 'react'

const SpendingContext = React.createContext([{}, () => {}])

const SpendingProvider = props => {
  const [state, setState] = useState({
    isLoadingPrice: false,
    priceToPay: 0,
    totalPrice: 0,
    totalPriceDisplay: 0,
    basicCoreCluster: null,
  })
  return (
    <SpendingContext.Provider value={[state, setState]}>
      {props.children}
    </SpendingContext.Provider>
  )
}

export {SpendingContext, SpendingProvider}
