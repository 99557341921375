export const nationalities = Object.freeze([
  {
    value: 'Italiana',
  },
  {
    value: 'Estera',
  },
])
export const participations = Object.freeze([
  {
    value: '2',
    label: 'Partecipo tramite bando ITACA INPS',
  },
  {
    value: '1',
    label: 'Partecipo privatamente',
  },
  {
    value: '4',
    label: 'Partecipo con azienda convenzionata',
  },
  // {
  //   value: '5',
  //   label: 'Partecipo tramite portale Welfare'
  // }
])

export const participationsLookup = Object.freeze({
  INPSIEME: '2',
  PRIVATO: '1',
  AZIENDA_CONVENZIONATA: '4',
  INPSNUOVO: '6'
  // WELFARE: '5'
})

export const bonusTypes = Object.freeze({
  AMICO: 'amico',
  FRATELLO: 'fratello',
})

export const disabilityTypes = Object.freeze({
  CARROZZINA: 'carrozzina',
  MOTORIA: 'motoria',
  ALTRO: 'altro',
})

export const stepsByName = Object.freeze({
  VACATION_CUSTOMER_DATA: 0,
  BONUS: 1,
  INFORMATION: 2,
  EXTRA_SERVICES: 3,
  RECAP: 4,
})

export const paymentsINPS = Object.freeze({
  TIPO_ACCONTO_SI: 'sicuro',
  TIPO_ACCONTO_NO: 'no_acconto',
})
