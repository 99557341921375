import React from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

const StepCustomerDialog = ({handleClose, open, title, children}) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle style={{paddingBottom: 0}}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
        <div style={{display: 'flex', justifyContent: 'center', margin: 8}}>
          <Button variant="contained" onClick={handleClose}>
            Ok
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default StepCustomerDialog
