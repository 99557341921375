import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import TopHeaderAvatar from './TopHeaderAvatar'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
})

function Logo({division}) {
  if (division === '1') {
    return (
      <img
        width="250"
        src="https://giocamondostudy.it/wp-content/uploads/2017/11/giocamondo-study_white_2018.png"
        title="Giocamondo study"
        alt="Giocamondo study logo"
      />
    )
  }

  if (division === '2') {
    return (
      <img
        width="250"
        src="https://www.lamiaestate.it/wp-content/uploads/2018/02/lamiaestate-logo-white.png" 
        title="La Mia Estate"
        alt="La Mia Estate logo"
      />
    )
  }

  //TODO: logo per step 3
  return (
    <img
      width="350"
      src="https://annidiargento.it/wp-content/uploads/annidiargento-logo-white-1.png"
      title="Anni di Argento"
      alt="Anni di Argento - Soggiorni Senior"
    />
  )
}

function getHeaderStyle(division) {
  if (division === '1') {
    return 'color-gs'
  }

  if (division === '2') {
    return 'color-lme'
  }

  return 'color-ada'
}
function TopHeader(props) {
  const classes = useStyles()
  const {division, rightTextRows, phone, onLogout, userData} = props

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="primary" className="navbar-dark">
        <Toolbar className={getHeaderStyle(division)}>
          <Grid container spacing={3} className="top-nav">
            <Grid item xs={7} sm={9} className="align-left">
              <Logo division={division} />
            </Grid>

            <Grid item xs={5} sm={3}>
              <div className="help-info">
                {rightTextRows.map(text => (
                  <React.Fragment key={text}>
                    <span>{text}</span>
                    <br />
                  </React.Fragment>
                ))}
                <a className="call" href={`tel:+39${phone}`}>
                  {phone}
                </a>
              </div>
            </Grid>
          </Grid>
          {userData && (
            <TopHeaderAvatar onLogout={onLogout} userData={userData} />
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}

TopHeader.propTypes = {
  division: PropTypes.string.isRequired,
  rightTextRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  phone: PropTypes.string.isRequired,
  onLogout: PropTypes.func,
  userData: PropTypes.object,
}

export default TopHeader
