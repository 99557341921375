import {useContext, useEffect} from 'react'
import {AppContext} from '../contexts/AppContext'
import {PackageSelectorContext} from '../contexts/PackageSelectorContext'
import * as actions from '../contexts/PackageSelectorActions'
import useApi from './useApi'

export default function usePkgSelectorContext(
  shouldLoadStructures = false,
  shouldLoadProgramTypologies = false
) {
  const [state, dispatch] = useContext(PackageSelectorContext)
  const [appState] = useContext(AppContext)
  const api = useApi()

  function reset() {
    dispatch(actions.reset())
  }

  function loadCatalogs() {
    dispatch(actions.loadCatalogs())
  }

  function loadNations() {
    dispatch(actions.loadNations())
  }
  function loadWebGroups() {
    dispatch(actions.loadWebGroups())
  }
  function loadPackages() {
    dispatch(actions.loadPackages())
  }
  function loadDepartureServices() {
    dispatch(actions.loadDepartureServices())
  }
  function loadStructures() {
    dispatch(actions.loadStructures())
  }
  function loadProgramTypologies() {
    dispatch(actions.loadProgramTypologies())
  }


  // Actually load catalogs
  useEffect(() => {
    async function _actuallyLoadCatalogs(division) {
      const catalogs = await api.getCatalogs()
      dispatch(actions.setCatalogs(catalogs))
    }
    if (appState.division != null && state.loadingCatalogs) {
      _actuallyLoadCatalogs(appState.division)
    }
  }, [dispatch, api, appState.division, state.loadingCatalogs])

  // Actually load nations
  useEffect(() => {
    async function _actuallyLoadNations(division) {
      const nations = (state.catalog)
        ? await api.getNationsByCatalog(division, state.catalog)
        : await api.getNations(division)
      dispatch(actions.setNations(nations))
    }
    if (appState.division != null && state.loadingNations) {
      _actuallyLoadNations(appState.division)
    }
  }, [dispatch, api, appState.division, state.loadingNations])

  // Actually load webGroups
  useEffect(() => {
    async function _actuallyLoadWebGroups(division, nation, resortTheme) {
      const webGroups = (state.catalog)
        ? await api.getPackageGroupsByCatalog(
          nation,
          division,
          state.catalog
        )
        : await api.getPackageGroups(
          nation,
          division,
          resortTheme
        ) 
      dispatch(actions.setWebGroups(webGroups))
    }

    if (
      appState.division != null &&
      state.nation != null &&
      state.loadingWebGroups
    ) {
      _actuallyLoadWebGroups(appState.division, state.nation, state.resortTheme)
    }
  }, [
    dispatch,
    api,
    appState.division,
    state.nation,
    state.loadingWebGroups,
    state,
  ])

  // Actually load packages
  useEffect(() => {
    // console.log('load packages effect trigger', {division: appState.division, webGroup: state.webGroup, loadingPackages: state.loadingPackages})
    async function _actuallyLoadPackages(division, webGroup) {
      const packages = (state.catalog)
        ? await api.getFormTurnsByCatalog(webGroup, division, state.catalog)
        : await api.getFormTurns(webGroup, division)
      dispatch(actions.setPackages(packages))
    }
    if (
      appState.division != null &&
      state.webGroup != null &&
      state.loadingPackages
    ) {
      // console.log('load packages effect trigger success')
      _actuallyLoadPackages(appState.division, state.webGroup)
    }
  }, [dispatch, api, appState.division, state.webGroup, state.loadingPackages])

  // Actually load departure services
  useEffect(() => {
    async function _actuallyLoadDepartureServices(division, choosenPackage) {
      const departureServices = await api.getDepartureServices(
        choosenPackage,
        division
      )
      const orderedDepartureServices = ([...departureServices] || []).sort(
        (a, b) => a.label.localeCompare(b.label)
      )
      dispatch(actions.setDepartureServices(orderedDepartureServices))
    }
    if (
      appState.division != null &&
      state.package != null &&
      state.loadingDepartureServices
    ) {
      _actuallyLoadDepartureServices(appState.division, state.package)
    }
  }, [
    dispatch,
    api,
    appState.division,
    state.package,
    state.loadingDepartureServices,
  ])

  // Actually load structures
  useEffect(() => {
    async function _actuallyLoadStructures(division, choosenPackage) {
      const structures = await api.getStructures(choosenPackage, division)
      dispatch(actions.setStructures(structures))
    }
    if (
      appState.division != null &&
      state.package != null &&
      state.loadingStructures
    ) {
      _actuallyLoadStructures(appState.division, state.package)
    }
  }, [dispatch, api, appState.division, state.package, state.loadingStructures])

  // Actually load program typologies
  useEffect(() => {
    async function _actuallyLoadProgramTypologies(division, choosenPackage) {
      const programTypologies = await api.getProgramTypologies(
        choosenPackage,
        division
      )
      dispatch(actions.setProgramTypologies(programTypologies))
    }
    if (
      appState.division != null &&
      state.package != null &&
      state.loadingProgramTypologies
    ) {
      _actuallyLoadProgramTypologies(appState.division, state.package)
    }
  }, [
    dispatch,
    api,
    appState.division,
    state.package,
    state.loadingProgramTypologies,
  ])

  function chooseCatalog(catalog) {
    dispatch(actions.chooseCatalog(catalog, true))
  }

  function chooseNation(nation) {
    const shouldLoadWebGroups = appState.division !== '3'
    dispatch(actions.chooseNation(nation, shouldLoadWebGroups))
  }
  function chooseResortTheme(resortTheme) {
    const shouldLoadWebGroups = true
    dispatch(actions.chooseResortTheme(resortTheme, shouldLoadWebGroups))
  }
  function chooseWebGroup(webGroup) {
    const shouldLoadPackages = true
    dispatch(actions.chooseWebGroup(webGroup, shouldLoadPackages))
  }
  function choosePackage(choosenPackage) {
    const shouldLoadDepartureServices = true
    // const shouldLoadStructures = false
    // const shouldLoadProgramTypologies = true
    dispatch(
      actions.choosePackage(
        choosenPackage,
        shouldLoadDepartureServices,
        shouldLoadStructures,
        shouldLoadProgramTypologies
      )
    )
  }
  function chooseDepartureService(departureService) {
    dispatch(actions.chooseDepartureService(departureService))
  }
  function chooseStructure(structure) {
    dispatch(actions.chooseStructure(structure))
  }
  function chooseProgramTypology(programTypology) {
    dispatch(actions.chooseProgramTypology(programTypology))
  }

  return {
    state,
    reset,
    loadCatalogs,
    loadNations,
    loadWebGroups,
    loadPackages,
    loadDepartureServices,
    loadStructures,
    loadProgramTypologies,
    chooseCatalog,
    chooseNation,
    chooseWebGroup,
    chooseResortTheme,
    choosePackage,
    chooseDepartureService,
    chooseStructure,
    chooseProgramTypology,
  }
}
