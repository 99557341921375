import React, {createContext, useReducer} from 'react'
import {initialState, reducer} from './UserReducer'

const UserContext = createContext([{}, () => {}])
const UserProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <UserContext.Provider value={[state, dispatch]}>
      {props.children}
    </UserContext.Provider>
  )
}

export {UserContext, UserProvider}
