import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import 'react-day-picker/lib/style.css'
import 'moment/locale/it'
import moment from 'moment'

function DateComponent(props) {
    const {
        value,
        onChange,
        disabled = false,
    } = props
    const [displayValue, setDisplayValue] = useState(moment(value).format('YYYY-MM-DD'));

    const onChangeDate = e => {
        const newDate = moment(new Date(e.target.value));
        const newDateFormatted = newDate.format('YYYY-MM-DD');
        setDisplayValue(newDateFormatted);
        if (!newDate.isValid() || e.target.value.trim().length < 8)
            onChange(undefined);
        else
            onChange(newDateFormatted);
    };

    useEffect(() => {
        setDisplayValue(moment(value).format('YYYY-MM-DD'))
    }, [value]);

    return (
        <input
            type="date"
            value={displayValue}
            onChange={onChangeDate}
            disabled={disabled}
            style={{
                marginTop: 25,
                height: 34
            }}
        />
    );
};

DateComponent.propTypes = {
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default DateComponent
