import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types'
import Notice from 'components/Notice'
import {getBookingFormNotice} from "../../specificUtils/api";

export default function StepCustomerDataNotice(props) {
    const [notice, setNotice] = useState();
    const {division} = props;

    const fetchData = async () => {
        try {
            const response = await getBookingFormNotice(division);
            if (response && response.data && response.data.enabled) {
                setNotice(response.data.text);
            }
        } catch (error) {
            console.error(error.response);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return notice ? (<Notice>
        <div dangerouslySetInnerHTML={{__html: notice}}/>
    </Notice>) : <></>
}
StepCustomerDataNotice.propTypes = {
    division: PropTypes.string.isRequired,
}
