import React, {useContext} from 'react'
import Sidebar from '../../components/Sidebar'
import StepCustomerData from './StepCustomerData'
import StepInformation from './StepInformation'
import StepExtraServices from './StepExtraServices'
import {stepsByName} from '../specificUtils/configs'
import Stepper from '../../components/Stepper'
import {AppContext} from '../../contexts/AppContext'
import useContextController from '../../hooks/useContextController'
import StepBonus from './StepBonus'
import StepPayment from "./StepPayment";

import {verifyDoubleBooking} from "../specificUtils/api";
import {Modal} from "react-async-popup";
import CustomerDataModal from "./CustomerDataModal";
import SidebarWrapper from "./SidebarWrapper";
import {useDialogContext} from "../../contexts/DialogContext";

// specific hsp
function getStepContent(activeStep, division) {
    switch (activeStep) {
        case stepsByName.VACATION_CUSTOMER_DATA:
            return <StepCustomerData/>
        case stepsByName.BONUS:
            return <StepBonus/>
        case stepsByName.INFORMATION:
            return <StepInformation/>
        case stepsByName.EXTRA_SERVICES:
            return <StepExtraServices/>
        case stepsByName.RECAP:
            return <StepPayment/>
        default:
            return <SidebarWrapper/>
        //   return (
        //     <div className="content-summary">
        //       <div className="text-center msg-confirm">
        //         <Typography variant="h4">
        //           Grazie per aver scelto
        //           {division === '1'
        //             ? "  un programma di studi all'estero Giocamondo Study"
        //             : ' un soggiorno estivo Giocamondo 2022. '}
        //         </Typography>
        //         <div className="container-msg">
        //           <p>
        //             Controlli l’allegato alla mail che ha appena ricevuto e
        //             verifichi che le informazioni inserite siano corrette.
        //             <br />
        //             In caso contrario ci contatti telefonicamente allo 0736343440
        //             oppure allo 0736336339
        //           </p>
        //           <p>Di seguito il riepilogo della sua prenotazione</p>
        //         </div>
        //         <SidebarWrapper />
        //       </div>
        //     </div>
        //   )
        // default:
        //   return <SidebarWrapper />
    }
}

export default function StepperWrapper() {
    const [state] = useContext(AppContext)
    const dialog = useDialogContext()
    const {validateVacationCustomerData, validateStep2InpsHsp} = useContextController()
    const steps = [
        'SOGGIORNO E ANAGRAFICA',
        'BONUS',
        'INFORMAZIONI UTILI',
        'PERSONALIZZA LA TUA ESPERIENZA',
        'CONFERMA',
    ]

    const stepValidations = [
        {
            step: stepsByName.VACATION_CUSTOMER_DATA,
            errorMessage: 'NO_MESSAGE',
            validateAsync: async () => {
                const step = stepValidations.find((x) => x.step === stepsByName.VACATION_CUSTOMER_DATA);
                if (state.division !== '1' && state.division !== '2') {
                    return true
                }

                if (!state.step1ChildFiscalCode) {
                    return true
                }

                const isDouble = await verifyDoubleBooking(
                    state.step1ChildFiscalCode,
                    state.division,
                    state.division === '1' ? '3' : '1'
                )

                if (!validateVacationCustomerData()) {
                    step.errorMessage = 'Ricontrolla le informazioni inserite. Alcuni dati sono mancanti o non validi.'
                    return false
                }

                const {show} = await Modal.new({
                    title: "Sei sicuro di aver inserito i dati correttamente?",
                    content: <CustomerDataModal state={state}/>,
                    cancelText: "No, voglio modificarli",
                    okText: "Sì dati corretti, continua"
                });
                const result = await show();
                if (!result) {
                    return false;
                } else {
                    step.errorMessage = 'Ricontrolla le informazioni inserite. Alcuni dati sono mancanti o non validi.'
                }

                if (isDouble) {
                    console.info("state", state);
                    console.info("dialog", dialog);
                    if (state.division === '1') {
                        await dialog.openDialog(
                            'Codice fiscale del partecipante già presente in una prenotazione attiva',
                            `Attenzione il codice fiscale del partecipante risulta già associato ad un'altra prenotazione attiva. Nel caso in cui si proseguisse con la prenotazione, la precedente verrà annullata e risulterà valida l’ultima completata temporalmente.`
                        )
                    } else if (state.division === '2') {
                        await dialog.openDialog(
                            'Codice fiscale del partecipante già presente in una prenotazione attiva',
                            `Attenzione il codice fiscale del partecipante risulta già associato ad un'altra prenotazione attiva. Nel caso in cui si proseguisse con la prenotazione, la precedente verrà annullata e risulterà valida l’ultima completata temporalmente.`
                        )
                    }
                }

                return true;
            },
        },
        {
            step: stepsByName.BONUS,
            errorMessage: 'NO_MESSAGE',
            validate: validateStep2InpsHsp,
            validateAsync: null,
        },
    ]

    return (
        <Stepper
            steps={steps}
            customerCareDescription={[
                'Per assistenza',
                'dal Lun al Ven: 8:30 - 17:30',
                //'dal Lun a Dom: 8:00 - 20:00',
            ]}
            customerCarePhone="0736.343440 - 0736.336339"
            getStepContent={() => getStepContent(state.activeStep, state.division)}
            stepValidations={stepValidations}
            stepsByName={stepsByName}
            Sidebar={SidebarWrapper}
            sendBookingButtonLabel="Vai alla conferma"
            canExpectPracticeData
        />
    )
}
