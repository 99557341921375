import React, {useContext, useEffect} from 'react'
import PropTypes from 'prop-types'
import useContextController from '../../hooks/useContextController'
import {AppContext} from '../../contexts/AppContext'
import StepperComponent from './Stepper'
import {UserContext} from '../../contexts/UserContext'
import {stepsByName} from "../../main/specificUtils/configs";
import {dateToServerString} from "../../main/specificUtils/api";
import useAuthentication,{TOKEN_KEY} from "../../hooks/useAuthentication";
import useApi from "../../hooks/useApi";

export default function Stepper(props) {
  const [state, setState] = useContext(AppContext)
  const [userState] = useContext(UserContext)
  const {signIn, checkIsUserLogged} = useAuthentication()
  const api = useApi()
  const {
    registerIntent,
    validateExtraServices,
    resetState,
  } = useContextController()
  const handleReset = () => {
    resetState()
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [state.activeStep])

  const memberSignUpHandler = async () => {
    // console.info("current state", state);
    let errors = [];
    if (state.step1Password === '') {
      errors.push('Devi inserire una password');
    }
    if (state.step1Password.length < 8) {
      errors.push('La lunghezza della password deve essere di almeno 8 caratteri');
    }

    if (!state.step4Privacy) {
      errors.push("L'accettazione della privacy è obbligatoria");
    }
    if (errors.length > 0) {
      return errors.join("\n");
    }
    const userData = {
      firstName: state.step1ParentName,
      lastName: state.step1ParentSurname,
      email1: state.step1ParentEmail,
      gender: state.step1ParentGender,
      birthPlace: state.step1ParentBirthCity.name,
      birthNation: state.step1ParentBirthNation,
      foreignBirthCity: state.step1ParentForeignBirthCity,
      birthDate: dateToServerString(state.step1ParentBirthDate),
      fiscalCode: state.step1ParentFiscalCode,
      mobilePhone: state.step1ParentPhone,
      privacyAccepted: state.step4Privacy,
      marketingAccepted: state.step4Marketing,
      cap: state.step1ParentCAP,
      address: state.step1ParentStreetAddress,
      // API non può elaborare un oggetto
      city: state.step1ParentResidenceCityObj
        ? Object.hasOwn(state.step1ParentResidenceCityObj, 'label')
          ? state.step1ParentResidenceCityObj.label
          : null
        : null,
      province: state.step1ParentProv,
      phone1: state.step1ParentPhone,
      phone2: state.step1ParentPhoneAlt,
    }

    // console.info("userData", userData);
    let result = "";
    try {
      setState(state => ({...state, isSubmitting: true}))
      const password = state.step1Password;
      const {email1: email} = userData
      // request for signup
      const signUpResult = await api.signUp(email, password, userData)
      // console.info("signUpResult", signUpResult);
      if (signUpResult.errors) {
        result = Object.values(signUpResult.errors).join("\n");
      } else {
        // TODO valutare di utilizzare la funziona `signUp` di `src/hooks/useAuthentication.js`
        // che oltre a registrare l'utente effettua anche il login; da verificare come gestire
        // gli errori
        await signIn(email, password, false)
      }
      // setState(state => ({...state, clubRegistration: true}))
    } catch (error) {
      console.error(error)
      console.error("error.response", error !== null ? error.response : null)
      result = (error !== null && error.response !== null && error.response.data !== null && error.response.data.errors !== null ? error.response.data.errors : null) ? Object.values(error.response.data.errors).join("\n") : "Non è stato possibile registrare l'utente. Email o CF già esistenti";
    } finally {
      setState(state => ({...state, isSubmitting: false}))
    }
    return result;
  }


  const registerStepIntent = async (expectedPracticeId = false) => {
    // actual call to api
    const {token} = userState // necessario per HSP
    const {result, data} = await registerIntent(expectedPracticeId, token)

    // error handling
    if (!result) {
      let errorMessage =
        'Ricontrolla le informazioni inserite. Alcuni dati sono mancanti o non validi.'
      if (typeof data === 'string') {
        errorMessage = data
      }
      // ReactDOM.findDOMNode(this).scrollIntoView()
      setImmediate(() => {
        alert(errorMessage)
        setState(state => ({...state, buttonDisabled: false}))
      })
      return
    }

    //Qui lo step è stato validato correttamente in termini di validazione campi
    //Posso fare la registrazione quindi se valgono le seguenti condizioni:
    //- Mi trovo al primo step
    //- L'utente non è loggato
    //- Non esiste nessun account con il Codice Fiscale inserito
    //- Mi trovo nel form main o hsp
    if ((!(await checkIsUserLogged())) && !state.memberExists && (state.activeStep === 0) && ((state.applicationType === 'main') || (state.applicationType === 'hsp') || (state.applicationType === 'docenti') || (state.applicationType === 'lin'))) {
      const signUpResult = await memberSignUpHandler();
      if (signUpResult !== "") {
        setImmediate(() => {
          alert(signUpResult)
          setState(state => ({...state, buttonDisabled: false}))
        })
        return
      }
    }


    // case result is ok
    setState(state => ({...state, activeStep: state.activeStep + 1}))

    // ReactDOM.findDOMNode(this).scrollIntoView()
  }

  const handleNext = async () => {
    //pulisco errori!
    const {stepValidations, stepsByName, canExpectPracticeData = false} = props
    setState(state => ({...state, errors: null}))
    const {activeStep} = state
    const genericErrorAlertMsg =
      'Ricontrolla le informazioni inserite. Alcuni dati sono mancanti o non validi.'

    // remember that activeStep is zero indexed
    // activeStep 0 => step 1
    switch (activeStep) {
      case stepsByName.EXTRA_SERVICES:
        if (!validateExtraServices()) {
          // ReactDOM.findDOMNode(this).scrollIntoView()
          setImmediate(() => {
            alert(
              'Controlla le tue preferenze.\nÈ necessario selezionare almeno una voce per ogni tipo di servizio.'
            )
          })
          return
        }
        registerStepIntent(canExpectPracticeData)
        setState(state => ({...state, buttonDisabled: true}))
        break

      case stepsByName.RECAP:
        handleReset()
        // ReactDOM.findDOMNode(this).scrollIntoView()
        return

      default:
        const validation = stepValidations.find(sv => sv.step === activeStep)
        if (
          !validation ||
          (!validation.validate && !validation.validateAsync)
        ) {
          registerStepIntent()
          break
        } else {
          let isStepValid = true
          if (validation.validate) {
            isStepValid = validation.validate()
          } else {
            isStepValid = await validation.validateAsync()
          }
          if (!isStepValid) {
            // ReactDOM.findDOMNode(this).scrollIntoView()
            ! state.isDouble && (validation.errorMessage !== 'NO_MESSAGE') && setImmediate(() => {
              alert(validation.errorMessage || genericErrorAlertMsg)
            })
            return
          }
          registerStepIntent()
          break
        }
    }
  }

  const handleBack = async () => {
    setState(state => ({...state, activeStep: state.activeStep - 1}))
    // ReactDOM.findDOMNode(this).scrollIntoView()
  }

  return (
    <StepperComponent
      onNext={handleNext}
      onBack={handleBack}
      onReset={handleReset}
      activeStep={state.activeStep}
      division={state.division}
      applicationType={state.applicationType}
      isSubmitting={state.isSubmitting}
      isSubmitButtonDisabled={state.buttonDisabled || false}
      fetchingExtraServices={!!state.fetchingExtraServices}
      {...props}
    />
  )
}

Stepper.propTypes = {
  stepValidations: PropTypes.array.isRequired,
  stepsByName: PropTypes.object.isRequired,
  canExpectPracticeData: PropTypes.bool,
}
