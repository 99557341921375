import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import BandoEVoucher from './BandoEVoucher'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
}))

export default function StepBonus(props) {
  const classes = useStyles()
  const {state, onEventChange} = props
  const {
    step1ChildFiscalCode,
    variablePracticeInpsNumber,
    step2Participation,
    step2VoucherCodes,
    errors,
  } = state

  return (
    <Grid container className={clsx(classes.root, 'step2-inner')} spacing={2}>
      <Grid item xs={12}>
        <BandoEVoucher
          step1ChildFiscalCode={step1ChildFiscalCode}
          onChange={onEventChange}
          value={step2VoucherCodes}
          step2Participation={step2Participation}
          variablePracticeInpsNumber={variablePracticeInpsNumber}
          errors={errors}
        />
      </Grid>
    </Grid>
  )
}

StepBonus.propTypes = {
  state: PropTypes.object.isRequired,
  onEventChange: PropTypes.func.isRequired,
  onSimpleCheckboxChange: PropTypes.func.isRequired,
  onBonusRequest: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
}
