import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExtraService from './ExtraService'
import ExtraServiceMobile from './ExtraServiceMobile'
import {useMediaQuery} from 'react-responsive'
import Accordion from "@material-ui/core/ExpansionPanel";
import clsx from "clsx";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import {Alert} from "@material-ui/lab";
import {Grid, Switch} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  progress: {
    margin: theme.spacing(2),
  },
  optionalExtraServices: {
    padding: '3%',
    background: '#fff',
    borderRadius: '5px',
    //border: 'solid 3px #236fbf',
    boxShadow: '0 0px 40px #ccc',
    margin: '10px 0',
  },
}))

export default function ExtraServicesWrapper(props) {
  const [isChecked, setIsChecked] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const classes = useStyles()
  const isMobile = useMediaQuery({query: '(max-width: 900px)'})

  const {
    allExtraServices,
    selectedExtraServices,
    fetchingExtraServices,
    onSelectExtraService,
    onDeselectOptionalExtraServices
  } = props

  let orderedExtraServices = [...allExtraServices];
  orderedExtraServices.sort((a, b) => {
    if (!a.optional && b.optional) return -1;
    if (a.optional && !b.optional) return 1;
    return 0;
  });

  const requiredExtraServices = [...orderedExtraServices.filter(x => !x.optional)];
  const optionalExtraServices = [...orderedExtraServices.filter(x => x.optional)];



    const toggleSwitch = () => {
        setIsChecked(!isChecked);
        setIsVisible(!isVisible);

        //Se isChecked = false, vuol dire che il valore subito successivo sarà true, quindi tutti quelli che ho selezionato opzionali, devo deselezionarli
        if (!isChecked) {
            let selectedServiceIds = [];
            optionalExtraServices.forEach(category => {
                const serviceIds = category.extraservices.map(service => service.value);
                const selectedIds = serviceIds.filter(id => selectedExtraServices.includes(id));
                selectedServiceIds = selectedServiceIds.concat(selectedIds);
            });
            //Deseleziono tutti gli extraservice
            onDeselectOptionalExtraServices(selectedServiceIds);
        }
    };

  return (
    <div className="extra-list">
      {allExtraServices && allExtraServices.length > 0 ? (
        <React.Fragment>
          {requiredExtraServices.map(extraservice => {

            /**
            * 20230116
            * Disable UI Desktop
            * Usiamo sempre UI Mobile

            if (isMobile) {
              return (
                <ExtraServiceMobile
                  extraservice={extraservice}
                  onSelectExtraService={onSelectExtraService}
                  selectedExtraServices={selectedExtraServices}
                />
              )
            } else {
              return (
                <ExtraService
                  extraservice={extraservice}
                  onSelectExtraService={onSelectExtraService}
                  selectedExtraServices={selectedExtraServices}
                />
              )
            } */
            return (
              <ExtraServiceMobile
                  isOptional={false}
                  extraservice={extraservice}
                  onSelectExtraService={onSelectExtraService}
                  selectedExtraServices={selectedExtraServices}
              />
            )
          })}

          {(optionalExtraServices && optionalExtraServices.length > 0) && (<>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={8}>
                <FormControlLabel
                    control={<Switch checked={isChecked} onChange={toggleSwitch} />}
                    //label="Chiedimelo successivamente in Area Riservata"
                    label="I seguenti servizi sono opzionali e possono essere selezionati successivamente in Area Riservata Giocamondo Club"
                    labelPlacement="start"
                />
              </Grid>
            </Grid>
            {isVisible && <section className='wrap-extra-opt'>
              <Typography variant="h5" className="box-light v-divider">
                Arricchisci la tua esperienza e rendi indimenticabile il tuo soggiorno<br/>
              </Typography>
              <Typography variant="body2">Valorizza il tuo viaggio, aggiungi uno o più dei seguenti servizi ed approfitta subito di un prezzo vantaggioso. Rendi il soggiorno un'esperienza ancora più straordinaria! </Typography>
              <Typography variant="body2"><strong>NB.</strong> Se non selezioni questi servizi ora, potrai farlo successivamente in area riservata Giocamondo Club.</Typography>
              {optionalExtraServices.map(optionalES =>
                  (
                    <div
                        key={optionalES.title + optionalES.subtitle}
                        className={clsx(classes.pricingTable, 'extra-item-opt')}>
                      <Accordion
                          variant="outlined"
                          square="false"
                          >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header">
                          {/*<Typography variant="h6">{optionalES.title}</Typography>*/}
                          <Typography variant="body1" className="extra-item-opt-title">{optionalES.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container direction="column">
                              <Alert severity="info">{optionalES.subtitle}</Alert>
                              <ExtraServiceMobile
                                  isOptional={true}
                                  extraservice={optionalES}
                                  onSelectExtraService={onSelectExtraService}
                                  selectedExtraServices={selectedExtraServices}
                              />
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
            </section>}
          </>)}

          {fetchingExtraServices && (
            <Typography variant="h5" className="box-light v-divider">
              Caricamento di ulteriori servizi extra in corso
              <CircularProgress className={classes.progress} />
            </Typography>
          )}
        </React.Fragment>
      ) : fetchingExtraServices ? (
        <Typography variant="h5" className="box-light v-divider">
          Caricamento dei servizi extra in corso
          <CircularProgress className={classes.progress} />
        </Typography>
      ) : (
        <Typography variant="h5" className="box-light v-divider">
          Nessun servizio extra per questo pacchetto è disponibile
        </Typography>
      )}
    </div>
  )
}

ExtraServicesWrapper.propTypes = {
  allExtraServices: PropTypes.array.isRequired,
  selectedExtraServices: PropTypes.array.isRequired,
  fetchingExtraServices: PropTypes.bool,
  onSelectExtraService: PropTypes.func.isRequired,
}
