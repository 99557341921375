import React, {useContext} from 'react'
import StepCustomerData from './StepCustomerData'
import StepInformation from './StepInformation'
import StepExtraServices from './StepExtraServices'
import StepBonus from './StepBonus'
import StepPayment from './StepPayment'
import {stepsByName} from '../specificUtils/configs'
import Stepper from '../../components/Stepper'
import {AppContext} from '../../contexts/AppContext'
import useContextController from '../../hooks/useContextController'
import SidebarWrapper from './SidebarWrapper'
import {useDialogContext} from '../../contexts/DialogContext'
import {PackageSelectorContext} from '../../contexts/PackageSelectorContext'

// specific main
function getStepContent(activeStep, division) {
  switch (activeStep) {
    case stepsByName.CUSTOMER_DATA:
      return <StepCustomerData />
    case stepsByName.BONUS:
      return <StepBonus />
    case stepsByName.INFORMATION:
      return <StepInformation />
    case stepsByName.EXTRA_SERVICES:
      return <StepExtraServices />
    case stepsByName.RECAP:
      return <StepPayment />
    default:
      return <SidebarWrapper />
  }
}

export default function StepperWrapper() {
  const [state] = useContext(AppContext)
  const [psState] = useContext(PackageSelectorContext)
  const dialog = useDialogContext()
  const {validateADACustomerData, validateADAStep3Data} = useContextController()
  const steps = [
    'IL MIO SOGGIORNO',
    'BONUS',
    'INFORMAZIONI UTILI',
    'PERSONALIZZA LA TUA ESPERIENZA',
    'CONFERMA',
  ]

  const stepValidations = [
    {
      step: stepsByName.CUSTOMER_DATA,
      validateAsync: async () => {
        const step = stepValidations.find((x) => x.step === stepsByName.CUSTOMER_DATA);
        if (state.division !== '3') {
          return true
        }

        const pack =
          psState.packages &&
          psState.packages.find(p => p.value === psState.package)

        if (pack === undefined) {
          return true
        }

        if (!validateADACustomerData()) {
          step.errorMessage = 'Ricontrolla le informazioni inserite. Alcuni dati sono mancanti o non validi.'
          return false
        }

        return true
      },
    },
    {
      step: stepsByName.INFORMATION,
      validateAsync: async () => {
        const step = stepValidations.find((x) => x.step === stepsByName.CUSTOMER_DATA);
        if (!validateADAStep3Data()) {
          step.errorMessage = 'Ricontrolla le informazioni inserite. Alcuni dati sono mancanti o non validi.'
          return false
        }
        return true
      },
    },
  ]

  return (
    <Stepper
      steps={steps}
      customerCareDescription={[
        'Per assistenza',
        'dal Lun al Ven: 8:30 - 17:30',
        //'dal Lun a Dom: 8:00 - 20:00',
      ]}
      customerCarePhone="0736.343440 - 0736.336339"
      getStepContent={() => getStepContent(state.activeStep, state.division)}
      stepValidations={stepValidations}
      stepsByName={stepsByName}
      Sidebar={SidebarWrapper}
      canExpectPracticeData
      sendBookingButtonLabel="Vai alla conferma"
    />
  )
}
