import React from 'react'
import PropTypes from 'prop-types'
// import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import loginImage from './assets/login_image_giocamondo.png'
// import SquareBox from 'components/SquareBox'

const useStyles = makeStyles({
  image: {
    maxHeight: '100%',
    width: '100%',
  },
  wrapper: {
    // padding: '0 1% 1% 1%',
    paddingTop: '0',
    paddingLeft: '1%',
    paddingRight: '1%',
    paddingBottom: '1%',
    border: 'solid 2px',
    borderColor: '#416991',
    backgroundColor: '#E2F2F2',
    display: 'flex',
    alignItems: 'flex-start',
  },
  imageContainer: {
    flex: 0.2,
  },
  mainContainer: {
    flex: 0.8,
    marginTop: 0,
  },
})

function MembershipBox({children}) {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.imageContainer}>
        <img
          alt="Login Giocamondo"
          className={classes.image}
          src={loginImage}
        />
      </div>
      <div className={classes.mainContainer}>{children}</div>
    </div>
  )
}

MembershipBox.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MembershipBox
