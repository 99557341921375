import React, {useContext} from 'react'
import {AppContext} from '../../../contexts/AppContext'
import IntoleranceUI from './Intolerance'

export default function Intolerance() {
  const [state, setState] = useContext(AppContext)
  const {
    errors,
    step3Intolerance,
    step3IntoleranceText,
    step3IntoleranceType,
    // step3MedicalCert,
  } = state
  const handleChange = label => event => {
    const {value} = event.target
    setState(state => ({...state, [label]: value}))
  }
  const handleFlagChange = label => event => {
    const {value} = event.target
    if (value === 'si') {
      setState(state => ({...state, [label]: value}))
    } else {
      switch (label) {
        case 'step3Intolerance':
          setState(state => ({
            ...state,
            step3Intolerance: value,
            step3IntoleranceText: '',
            step3IntoleranceType: '',
            // step3MedicalCert: '',
          }))
          break
        default:
          setState(state => ({...state, [label]: value}))
          break
      }
    }
  }
  return (
    <IntoleranceUI
      errors={errors}
      step3Intolerance={step3Intolerance}
      step3IntoleranceText={step3IntoleranceText}
      step3IntoleranceType={step3IntoleranceType}
      onChange={handleChange}
      onFlagChange={handleFlagChange}
    />
  )
}
