import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
// import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ImageEuro from '@material-ui/icons/EuroSymbol'
import ImageStar from '@material-ui/icons/StarRate'
import Price from '../Price'
import moment from 'moment'
import {Alert, AlertTitle} from '@material-ui/lab'
import {Typography} from '@material-ui/core'
import { PaymentContext } from '../../contexts/PaymentContext'
import {CATALOGS} from "../PackageSelector/PackageSelector";

function JotUrlImg({
  activeStep,
  index,
  division,
  summaryStepIndex,
  valorePratica,
  valorePraticaNetto,
  valorePraticaVisualizzato,
  step1ChildFiscalCode,
  step1ParentFiscalCode,
  ...props
}) {
  const jotUrlParams = new URLSearchParams('comm=0&param=')
  if (0 === activeStep && index === 0) {
    jotUrlParams.append(
      'id',
      '553575345571596335374f6b3358784b7654755565513d3d'
    )
    jotUrlParams.append('val', '0')
  }
  // non serve avere due id per per le due divisioni
  // if (0 === activeStep && index === 0 && division === '2') {
  //     jotUrlParams.append('id', '7555472b5347456d6d354c7479694e46444836666b773d3d');
  //     jotUrlParams.append('val', '0');
  // }

  if (
    summaryStepIndex === activeStep &&
    summaryStepIndex === index
  ) {
    jotUrlParams.append(
      'id',
      '3352705a346373443373464258315041777a477778413d3d'
    )
    jotUrlParams.append('val', valorePraticaVisualizzato)
    if (division === 3) {
      jotUrlParams.append('ep03', step1ParentFiscalCode)
    } else {
      jotUrlParams.append('ep03', step1ChildFiscalCode)
    }
  }

  // query_vars per joturl
  const searchParams = new URLSearchParams(window.location.search)
  if (searchParams.has('ep00') === true) {
    jotUrlParams.append('ep00', searchParams.get('ep00'))
  }
  if (searchParams.has('ep01') === true) {
    jotUrlParams.append('ep01', searchParams.get('ep01'))
  }
  if (searchParams.has('ep02') === true) {
    jotUrlParams.append('ep02', searchParams.get('ep02'))
  }
  let asd = ''
  let saldo = ''
  let netto = ''
  let saldoVisualizzato = ''
  if (typeof jotUrlParams !== 'undefined') {
    // console.log(jotUrlParams.toString());
    const src = jotUrlParams.toString()
    asd = (
      <img
        height="0"
        width="0"
        alt=""
        src={`https://www.joturl.com/c/?${src}`}
      />
    )
    saldo = (
      <span id={`saldo${index + 1}`}>
        {valorePratica}
      </span>
    )
    netto = (
      <span id={`netto${index + 1}`}>{valorePraticaNetto}</span>
    )
    saldoVisualizzato = (
      <span id={`saldoVisualizzato${index + 1}`}>{valorePraticaVisualizzato}</span>
    )
  }

  return <React.Fragment>{asd}{saldo}{netto}{saldoVisualizzato}</React.Fragment>
}



const DepositComponent = ({ className, depositPrice = 0 }) =>
  !!depositPrice ? <div className={className}>
    <ListItem className="aside-title-deposit" style={{ justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar className="highlight-color" style={{ height: 24, width: 24, marginRight: 8 }}>
          <ImageEuro style={{ fontSize: 13 }} />
        </Avatar>
        <Typography>Acconto per conferma</Typography>
      </div>
      <p style={{ background: '#febd30', color: '#111', paddingLeft: 16, paddingTop: 2, paddingBottom: 2, borderRadius: 5, fontWeight: 'bold' }}>
        €&nbsp;{depositPrice / 100}
      </p>
    </ListItem>
  </div> : null


export default function Sidebar(props) {
  const [paymentContext] = useContext(PaymentContext)
  const hasExtraPaymentServices = paymentContext.hasExtraPaymentServices

  const {
    enableTagManager = false,
    nationTitle = 'Paese',
    groupTitle = 'Pacchetto',
    packageTitle,
    departureTitle = 'Partenza',
    structureTitle = 'Struttura',
    programTypologyTitle = 'Tipologia di programma',
    selectedGroup,
    selectedTurn,
    // alternativeGroup,
    // alternativeTurn,
    bonusValue,
    contributoValue,
    appState,
    packageSelectorState,
    // alternativePsState,
    priceToPay = 0,
    totalPrice = 0,
    visualTotalPrice = null,
    depositPrice = 0,
    summaryStepIndex,
    hasPaymentError = false,
  } = props

  const {
    nation: step1Nation,
    catalog: step1Catalog,
    departureService: step1DepartureServiceIds,
    departureServices: cities,
    structure: step1Structure,
    structures,
    programTypology: step1ProgramTypology,
    programTypologies,
  } = packageSelectorState

  const {
    division,
    // parent
    step1ParentName,
    step1ParentSurname,
    step1ParentFiscalCode,
    step1ParentStreetAddress,
    step1ParentResidenceCity,
    step1ParentCAP,
    step1ParentProv,
    step1ParentEmail,
    step1ParentPhone,
    step1ParentPhoneAlt,
    // child
    step1ChildName,
    step1ChildSurname,
    step1ChildFiscalCode,
    step1ChildStreetAddress,
    step1ChildResidenceCity,
    step1ChildCAP,
    step1ChildProv,
    step1ChildEmail,
    step1ChildPhone,
    step1ChildCellPhone,
    step1ChildDocType,
    step1ChildDocNumber,
    step1ChildDocEmission,
    step1ChildDocExpiration,
    // bonus
    step2Participationlabel,
    step2InpsContributeQuantity,
    step2Grade,
    step2BonusRequested,
    step2BonusType,
    step2BonusSurname,
    step2BonusName,
    step2Portal,
    step2WelfareCode,
    // other
    activeStep,
    step4Marketing,
  } = appState

  return (
    <Grid item xs={12} sm={12} className="aside-booking">
      <List className="aside-list">
        <div className="aside-panel">
          <ListItem className="aside-title">
            <ListItemAvatar>
              <Avatar className="highlight-color">
                <ImageEuro />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Riepilogo prenotazione" />
          </ListItem>
          <ListItem className="aside-content">
            <dl className="price-si">
              <li />
              <Price>
                {hasExtraPaymentServices && <DepositComponent className="aside-panel-deposit-mobile" depositPrice={depositPrice}/>}
              </Price>
            </dl>
          </ListItem>
        </div>
        {hasExtraPaymentServices && <DepositComponent className="aside-panel-deposit" depositPrice={depositPrice}/>}
        {hasPaymentError && (
          <div className="aside-panel">
            <Alert severity="error">
              <AlertTitle>Attenzione, si è verificato un problema.</AlertTitle>
              Contatta il nostro staff per ricevere assistenza, dal Lun al Ven:
              8:30 - 17:30, ai numeri di telefono 0736
              343440 - 0736 336339 Oppure riprova più tardi.
            </Alert>

            {/*<Typography variant="body1" gutterBottom>
              Attenzione, si è verificato un problema. Contatta il nostro staff
              per ricevere assistenza, dal Lun al Ven: 8:30 - 19:30 e Sab: 9:00 - 17:00
              ai numeri di telefono 0736 343440 - 0736 336339 Oppure riprova più
              tardi.
          </Typography>
          */}
          </div>
        )}

        <div className="aside-panel">
          <ListItem className="aside-title">
            <ListItemAvatar>
              <Avatar className="highlight-color">
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="La tua prenotazione" />
          </ListItem>

          <ListItem className="aside-content">
            <ul>
              <li className="pak-optioned item-no-style">
                <ul>
                  { !!step1Catalog && (
                    <li>{`Catalogo : ${CATALOGS.find((x) => x.value == step1Catalog).label}`}</li>
                  )}
                  {!!step1Nation && (
                    <li>{`${nationTitle} : ${step1Nation}`}</li>
                  )}
                  {!!selectedGroup && (
                    <li>
                      {`${groupTitle} : `}
                      {!!selectedTurn && selectedTurn.contractid != null
                        ? `${selectedGroup.label} - ${selectedTurn.contractid}`
                        : selectedGroup.label}
                    </li>
                  )}
                  {!!selectedTurn && (
                    <li>
                      {!!packageTitle
                        ? `${packageTitle} : ${selectedTurn.label}`
                        : selectedTurn.label}
                    </li>
                  )}
                  {!!step1DepartureServiceIds && (
                    <li>
                      {`${departureTitle} : `}
                      {
                        cities.filter(item => {
                          return item.value === step1DepartureServiceIds
                        }).length >0 && cities.filter(item => {return item.value === step1DepartureServiceIds})[0].label || ""
                      }
                    </li>
                  )}
                  {!!step1Structure && (
                    <li>
                      {`${structureTitle} : `}
                      {
                        structures.filter(item => item.value === step1Structure)[0] ? structures.filter(item => item.value === step1Structure)[0].label : ''
                      }
                    </li>
                  )}
                  {!!step1ProgramTypology && (
                    <li>
                      {`${programTypologyTitle} : `}
                      {
                        programTypologies.filter(item => item.value === step1ProgramTypology)[0] ? programTypologies.filter(item => item.value === step1ProgramTypology)[0].label : ''
                      }
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </ListItem>
        </div>

        <div className="aside-panel">
          <ListItem className="aside-title">
            <ListItemAvatar>
              <Avatar className="highlight-color">
                <ImageStar />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Dati anagrafici" />
          </ListItem>
          <ListItem className="aside-content">
            <ul className="parent-buyer">
              {step1ParentSurname && step1ParentName ? (
                <li>Cliente: {step1ParentName + ' ' + step1ParentSurname}</li>
              ) : null}
              {step1ParentFiscalCode ? (
                <li>CF: {step1ParentFiscalCode}</li>
              ) : null}
              {step1ParentStreetAddress &&
              step1ParentResidenceCity &&
              step1ParentCAP &&
              step1ParentProv ? (
                <li>
                  Indirizzo: {step1ParentStreetAddress} - {step1ParentCAP}{' '}
                  {step1ParentResidenceCity} ({step1ParentProv})
                </li>
              ) : null}
              {!!step1ParentEmail && (
                <li>
                  Email: <span id="mail-buyer">{step1ParentEmail}</span>
                </li>
              )}
              {step1ParentPhoneAlt ? (
                <li>Telefono fisso: {step1ParentPhoneAlt}</li>
              ) : null}
              {!!step1ParentPhone && (
                <li>Telefono cellulare: {step1ParentPhone}</li>
              )}
            </ul>

            <ul className="child-partecipant">
              {step1ChildSurname && step1ChildName ? (
                <li>
                  Partecipante : {step1ChildName + ' ' + step1ChildSurname}
                </li>
              ) : null}
              {step1ChildFiscalCode ? (
                <li>CF: {step1ChildFiscalCode}</li>
              ) : null}
              {step1ChildStreetAddress &&
              step1ChildResidenceCity &&
              step1ChildCAP &&
              step1ChildProv ? (
                <li>
                  Indirizzo: {step1ChildStreetAddress} - {step1ChildCAP}{' '}
                  {step1ChildResidenceCity} ({step1ChildProv})
                </li>
              ) : null}
              {!!step1ChildEmail && <li>Email: {step1ChildEmail}</li>}
              {step1ChildPhone ? (
                <li>Telefono fisso: {step1ChildPhone}</li>
              ) : null}
              {!!step1ChildCellPhone && (
                <li>Telefono cellulare: {step1ChildCellPhone}</li>
              )}
              {!!step1ChildDocType && !!step1ChildDocNumber && (
                <React.Fragment>
                  <li>
                    {step1ChildDocType === 'passport'
                      ? 'Passaporto'
                      : step1ChildDocType === 'idCard'
                      ? "Carta d'identità"
                      : 'Documento'}
                    {': '}
                    {step1ChildDocNumber}
                  </li>
                  {!!step1ChildDocEmission && (
                    <li>
                      Data emissione documento:{' '}
                      {moment(step1ChildDocEmission).format('DD/MM/YYYY')}
                    </li>
                  )}
                  {!!step1ChildDocExpiration && (
                    <li>
                      Data scadenza documento:{' '}
                      {moment(step1ChildDocExpiration).format('DD/MM/YYYY')}
                    </li>
                  )}
                </React.Fragment>
              )}
            </ul>

            <ul className="info">
              {!!step2Participationlabel && <li>{step2Participationlabel}</li>}
              {!!step2InpsContributeQuantity && (
                <li>Contributo INPS : {step2InpsContributeQuantity}</li>
              )}
              {!!step2Grade && <li>Media : {step2Grade}</li>}
              {!!step2BonusRequested && (
                <li>
                  {step2BonusType} : {step2BonusSurname + ' ' + step2BonusName}
                </li>
              )}

              {!!step2Portal && (
                <li>
                  Portale : {step2Portal} {step2WelfareCode}
                </li>
              )}
            </ul>
          </ListItem>
        </div>
      </List>
      {enableTagManager && (
        <div className="tag_mng">
          <ul>
            {step4Marketing === 'si' && (
              <li>
                <span id="acconsento-mkt">sì</span>
              </li>
            )}
            {Array(summaryStepIndex + 1)
              .fill(0)
              .map((item, index) => (
                <li
                  key={index}
                  id={`tag_mng_step${index + 1}`}
                  className={clsx({'active-step': activeStep === index})}>
                  <JotUrlImg
                    activeStep={activeStep}
                    index={index}
                    division={division}
                    summaryStepIndex={summaryStepIndex}
                    valorePratica={
                      (totalPrice - bonusValue) / 100
                    }
                    valorePraticaNetto={
                      totalPrice / 100
                    }
                    valorePraticaVisualizzato={
                      visualTotalPrice / 100
                    }
                    step1ChildFiscalCode={step1ChildFiscalCode}
                    step1ParentFiscalCode={step1ParentFiscalCode}
                  />
                </li>
              ))}
          </ul>
        </div>
      )}
    </Grid>
  )
}

Sidebar.propTypes = {
  enableTagManager: PropTypes.bool,
  nationTitle: PropTypes.string,
  groupTitle: PropTypes.string,
  packageTitle: PropTypes.string.isRequired,
  departureTitle: PropTypes.string,
  structureTitle: PropTypes.string,
  programTypologyTitle: PropTypes.string,
  selectedGroup: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }),
  selectedTurn: PropTypes.shape({
    label: PropTypes.string.isRequired,
    contractid: PropTypes.string,
  }),
  bonusValue: PropTypes.number.isRequired,
  contributoValue: PropTypes.number.isRequired,
  appState: PropTypes.object.isRequired,
  packageSelectorState: PropTypes.object.isRequired,
  priceToPay: PropTypes.number,
  visualTotalPrice: PropTypes.number,
  summaryStepIndex: PropTypes.number.isRequired,
  hasPaymentError: PropTypes.bool,
}
