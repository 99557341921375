import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import Intolerance from '../../../components/Intolerance'
import Disability from '../../../components/Disability'
import Autocomplete from "../../../components/Autocomplete";
import {getPackage} from "../../specificUtils/api";

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField8: {
        width: '62.666667%',
        marginLeft: 0,
        marginRight: 0,
    },
}))

/**
 * @stefano: i campi step3RoommateSurname, step3RoommateName sono deprecati. Li ho lasciati perché su hsp devono esserci
 * */
export default function StepInformation(props) {
    const classes = useStyles()
    const {
        onChange,
        onSaveStep3CodiceDocente,
        step3NoteLeaving,
        // step3RoommateSurname,
        // step3RoommateName,
        step3NoteRoommate,
        step3TeacherFullName,
        step3CodiceDocente,
        packageId,
        division,
        errors,
    } = props
    // const errors = props.errors || {}

    return (
        <form className={classes.container} noValidate autoComplete="off">
            <Grid container className={classes.root} spacing={2}>

                <Grid item xs={12}>
                    <Typography variant="h6">Informazioni utili</Typography>
                </Grid>

                <Grid item xs={12}>
                    <div className="box-light">
                        <Typography variant="body1">
                            Codice docente
                            <br/>
                            Inserisci il codice fornito dal docente accompagnatore
                        </Typography>
                        <Autocomplete
                            placeholder={"Codice docente"}
                            value={step3CodiceDocente}
                            getOptions={async (input) => {
                                const packages = await getPackage(packageId, division);
                                if (packages.length > 0) {
                                    const pack = packages[0];
                                    if (pack.codiceDocente && pack.codiceDocente.toLowerCase().includes(input.toLowerCase())) {
                                        return [
                                            {value: pack.codiceDocente, label: pack.codiceDocente},
                                        ];
                                    } else {
                                        return [];
                                    }
                                } else {
                                    return [];
                                }
                            }}
                            onSelect={(item) => onSaveStep3CodiceDocente(item)}
                            onDelete={() => {
                            }}
                        />
                        {errors ?
                            (errors.step3CodiceDocente && <Typography variant="colorError">{errors.step3CodiceDocente}</Typography>)
                            : <></>
                        }
                    </div>
                </Grid>

                {/*<Grid item xs={12}>*/}
                {/*    <Disability/>*/}
                {/*</Grid>*/}

                <Grid item xs={12}>
                    <Intolerance/>
                </Grid>

                <Grid item xs={12}>
                    <div className="box-light">
                        <Typography variant="body1">
                            Inserisci il nome/i del tuo compagno/i con cui desideri
                            condividere la stanza
                            {/* Il partecipante preferisce dormire in stanza con? */}
                        </Typography>
                        <TextField
                            id="note"
                            label="Inserisci il/i nominativo/i"
                            className={classes.textField8}
                            value={step3NoteRoommate}
                            onChange={onChange('step3NoteRoommate')}
                            margin="normal"
                        />
                        {/*<TextField*/}
                        {/*  id="cognome"*/}
                        {/*  label="Inserisci il/i nominativo/i"*/}
                        {/*  className={classes.textField8}*/}
                        {/*  value={step3RoommateSurname}*/}
                        {/*  onChange={onChange('step3RoommateSurname')}*/}
                        {/*  margin="normal"*/}
                        {/*/>*/}
                        {/*
            <TextField
              id="nome"
              label="Nome"
              className={classes.textField}
              value={step3RoommateName}
              onChange={onChange('step3RoommateName')}
              margin="normal"
            /> */}
                        <p>
                            <small>
                                Qualora non si indicasse nessun nominativo, lo staff provvederà
                                alla sistemazione con altri coetanei in base alla tipologia della stanza.
                            </small>
                        </p>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div className="box-light">
                        <FormLabel component="legend">
                            Eventuali comunicazioni relative al soggiorno
                        </FormLabel>
                        <TextField
                            id="multiline-flexible"
                            multiline
                            rowsMax="4"
                            value={step3NoteLeaving}
                            className={classes.textField8}
                            margin="normal"
                            onChange={onChange('step3NoteLeaving')}
                        />
                    </div>
                </Grid>
            </Grid>
        </form>
    )
}

StepInformation.propTypes = {
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object,
    step3NoteLeaving: PropTypes.string,
    step3RoommateSurname: PropTypes.string,
    step3RoommateName: PropTypes.string,
    step3NoteRoommate: PropTypes.string,
}
