import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SquareBox from 'components/SquareBox'

const useStyles = makeStyles(theme => ({
  text: {
    fontStyle: 'italic',
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  verticalSpacer: {
    height: theme.spacing(1),
  },
  wrapper: {
    padding: theme.spacing(2),
  },
}))

function MembershipSignupSuccessMessage() {
  const classes = useStyles()

  return (
    <SquareBox backgroundColor="#A0E8BD" borderColor="#00B219" borderWidth={1}>
      <div className={`${classes.text} ${classes.wrapper}`}>
        <Typography>
          La tua <span className={classes.textBold}>registrazione</span>{' '}
          &egrave; stata <span className={classes.textBold}>effettuata</span>!
        </Typography>
        <div className={classes.verticalSpacer} />
        <Typography variant="body2">
          Puoi procedere alla prenotazione del tuo pacchetto!
        </Typography>
        <Typography variant="body2">
          Potrai verificare le tue credenziali all'indirizzo di posta scelto.
        </Typography>
      </div>
    </SquareBox>
  )
}

export default MembershipSignupSuccessMessage
