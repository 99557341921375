import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

export default function MembershipPasswordComponent(props) {
  const {
    componentStyle = null,
    fullWidth = false,
    error,
    label,
    value,
    onChange,
    placeholder,
    helperText,
    showPassword: ancestorShowPassword = false,
    onShowPasswordClicked,
    // disableUnderline = false,
  } = props
  const [showPassword, setShowPassword] = useState(ancestorShowPassword)
  const handleClickShowPassword = () => {
    setShowPassword(showPassword => !showPassword)
    if (typeof onShowPasswordClicked === 'function') {
      onShowPasswordClicked()
    }
  }

  useEffect(() => {
    if (ancestorShowPassword != null) {
      setShowPassword(ancestorShowPassword)
    }
  }, [ancestorShowPassword])

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <FormControl className={componentStyle} fullWidth={fullWidth} error={error}>
      {!!label && (
        <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
      )}
      <Input
        id="standard-adornment-password"
        type={showPassword ? 'text' : 'password'}
        value={value || ''}
        disableUnderline
        onChange={event => {
          onChange(event.target.value)
        }}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

MembershipPasswordComponent.propTypes = {
  componentStyle: PropTypes.any,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  showPassword: PropTypes.bool,
  onShowPasswordClicked: PropTypes.func,
}
