import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  wrapper: {
    border: 'solid 2px',
  },
})

function SquareBox({backgroundColor, borderColor, borderWidth, ...others}) {
  const classes = useStyles()

  return (
    <div
      className={classes.wrapper}
      style={{backgroundColor, borderColor, borderWidth}}
      {...others}
    />
  )
}

SquareBox.defaultProps = {
  backgroundColor: 'white',
  borderColor: 'black',
  borderWidth: '2px',
}

SquareBox.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  children: PropTypes.node.isRequired,
}

export default SquareBox
