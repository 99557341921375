import React, {useContext} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import {paymentsINPS} from '../../specificUtils/configs'
import StepPaymentMethod from './StepPaymentMethod'
import Payment from '../../../components/Payment'
import {PackageSelectorContext} from '../../../contexts/PackageSelectorContext'

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(3),
    },
    formLabel: {
        fontSize: '1.7rem',
        color: 'rgba(0, 0, 0, 1)!important',
        marginBottom: '1rem',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    boxlighter: {
        background: 'rgb(255, 250, 229)',
        padding: '2%!important',
    },
}))

export default function StepPaymentAllowFree(props) {
    const {onTipo, paymentState, onConfirm, onUpdatePayment, state, amount} = props
    const {tipo, updatingPayment, updatedPayment} = paymentState
    const classes = useStyles()

    const [psState] = useContext(PackageSelectorContext)
    const advancePaymentINPS = psState.packages.find(
        pack => pack.value === psState.package
    ).advancePaymentINPS
    const flagDomandaVincitoreINPS = state['formPrenotazione.domandaVincitoreINPS'];

    const amountCondizionato = flagDomandaVincitoreINPS ? advancePaymentINPS : amount

    const confirmBooking = async () => {
        try {
            await onUpdatePayment()
            await onConfirm()
        } catch (error) {
            console.error(error)
        }
    }

    const handleChange = event => {
        onTipo(event.target.value)
    }

    return (
        <div>
            <Grid container spacing={3} className="panel" alignItems="center">
                <Grid item xs={12}>
                    {flagDomandaVincitoreINPS && <FormControl component="fieldset" className={classes.formControl}>
                        <FormLabel component="legend" className={classes.formLabel}>
                            Qualora non risultassi vincitore del bando Inps, partirai ugualmente senza contributo?
                        </FormLabel>
                        <RadioGroup
                            aria-label="tipologia"
                            name="tipologia"
                            value={tipo}
                            disabled={updatingPayment || updatedPayment}
                            onChange={handleChange}>
                            <FormControlLabel
                                value={paymentsINPS.TIPO_ACCONTO_NO}
                                control={<Radio/>}
                                label={
                                    'No, partirò solo se vincitore bando INPS'
                                }
                            />
                            <FormControlLabel
                                value={paymentsINPS.TIPO_ACCONTO_SI}
                                control={<Radio/>}
                                label={`Sì, Parto Sicuro. Intendo mantenere il posto e partire anche senza contributo bando INPS (conferma la prenotazione versando un acconto di € ${advancePaymentINPS /
                                100} non rimborsabile, che sarà detratto dal saldo totale)`}
                            />
                        </RadioGroup>
                    </FormControl>}
                </Grid>
                {tipo != null && tipo === paymentsINPS.TIPO_ACCONTO_NO ? (
                    <>
                        <Grid item xs={12} sm={1}></Grid>
                        <Grid
                            item
                            xs={12}
                            sm={10}
                            className={classes.boxlighter}
                            align-items="center">
                            <Button
                                variant="contained"
                                onClick={confirmBooking}
                                color="secondary">
                                Concludi la prenotazione
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={1}></Grid>
                    </>
                ) : (((tipo != null) && (tipo === paymentsINPS.TIPO_ACCONTO_SI)) || !flagDomandaVincitoreINPS) ? (
                    <StepPaymentMethod {...props} amount={amountCondizionato}/>
                ) : null}
            </Grid>
        </div>
    )
}
