import React, {useContext} from 'react'
import SidebarComponent from './Sidebar'
import {AppContext} from 'contexts/AppContext'
import {PackageSelectorContext} from 'contexts/PackageSelectorContext'
import {SpendingContext} from 'contexts/SpendingContext'
import {PaymentContext} from 'contexts/PaymentContext'

export default function Sidebar(props) {
  const [appState] = useContext(AppContext)
  const [psState] = useContext(PackageSelectorContext)
  const [spendingState] = useContext(SpendingContext)
  const [paymentState] = useContext(PaymentContext)
  const {webGroup, package: packageId, webGroups, packages} = psState

  const {
    // bonus
    step2InpsServiceId,
    inpsServices,
    step2BonusId,
    step2bonuses,
  } = appState

  const {updatePaymentError, confirmError, payError} = paymentState
  const hasPaymentError = !!updatePaymentError || !!confirmError || !!payError
  const selectedGroup = webGroups.find(item => item.value === webGroup)
  const selectedTurn = packages.find(t => t.value === packageId)

  let bonusValue = 0
  if (step2BonusId) {
    const selectedBonus = step2bonuses.find(b => b.value === step2BonusId)
    if (selectedBonus) {
      //calc bonus value
      bonusValue = selectedBonus.realvalue
    }
  }

  let contributoValue = 0
  if (step2InpsServiceId) {
    const contributoInps = inpsServices.find(
      is => `${is.value}` === step2InpsServiceId
    )
    if (contributoInps) {
      contributoValue = contributoInps.isVisualPrice ? contributoInps.visualPriceAmount : contributoInps.realvalue
    }
  }

  return (
    <SidebarComponent
      {...props}
      selectedGroup={selectedGroup}
      selectedTurn={selectedTurn}
      // alternativeGroup={alternativeGroup}
      // alternativeTurn={alternativeTurn}
      bonusValue={bonusValue}
      contributoValue={contributoValue}
      appState={appState}
      packageSelectorState={psState}
      // alternativePsState={alternativePsState}
      priceToPay={spendingState.priceToPay}
      totalPrice={spendingState.totalPrice}
      visualTotalPrice={spendingState.visualTotalPrice}
      depositPrice={spendingState.depositPrice}
      hasPaymentError={hasPaymentError}
    />
  )
}
