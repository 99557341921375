import React from 'react'
import PropTypes from 'prop-types'
import PackageSelector from '../../components/PackageSelector'

export default function PackageSelectorWrapper({title}) {
  return (
    <PackageSelector
      title={title}
      groupsTitle="Hotel"
      turnsTitle="Seleziona periodo"
      // noTurnsText="Non ci sono periodi di partenza disponibili per questa durata"
      enableStructures
      enableTurnPrice
      enableResortTheme
    />
  )
}

PackageSelectorWrapper.propTypes = {
  title: PropTypes.string,
}
