import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {PaymentContext} from 'contexts/PaymentContext'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  itemExtraSuggested: {
    /*backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#01a34a',*/
  },
  itemExtraSuggestedContainer: {
    width: '100%',
    position: 'absolute',
    top: '-8px',
    left: '0px',
    margin: 'auto',
  },
  itemHidden: {
    display: 'none',
  },
}))

export default function ExtraService(props) {
  const classes = useStyles()
  const [paymentState, setPaymentState] = useContext(PaymentContext)
  const {
    data,
    siblingNumber,
    selectedExtraServices,
    onSelectExtraService,
  } = props
  let clazz = 'pricing-item mdl-cell mdl-cell--2-col mdl-shadow--2dp '
  let label_btn = 'Seleziona'
  let label_remove = ''
  if (selectedExtraServices && selectedExtraServices.includes(data.value)) {
    clazz += 'selected'
    label_btn = 'selezionato'
    label_remove = 'clicca nuovamente per rimuovere'
  }
  let mdValue
  switch (siblingNumber) {
    case 0:
      mdValue = 12
      break
    case 1:
      mdValue = 6
      break
    default:
      mdValue = 4
      break
  }
  
  // console.log(paymentState, props)
  const value = data.isVisualPrice ? data.visualPriceAmount : data.realvalue
  return (
    <Grid
      onClick={() => {
        setPaymentState(state => ({ ...state, isVisualPrice: data.isVisualPrice || false }))
        onSelectExtraService(data.value)
      }}
      item
      xs={12}
      md={mdValue}
      className={data.suggested ? clazz + ' suggest-item' : clazz}>
      <div className="item-extra-container">
        <div className="pricing-title">
          {data.label}
          {data.suggested && (
            <div
              className={
                classes.itemExtraSuggestedContainer + ' suggest-label'
              }>
              <small className={classes.itemExtraSuggested}>PIÙ SCELTO</small>
            </div>
          )}
        </div>
        {/*<div className="pricing-value itemHidden">€ {data.realvalue / 100}</div>*/}
        <div className="pricing-value">€ {value / 100}</div>
        <div className="mdl-list list">{data.description}</div>
      </div>
      <div className="item-extra-cta">
        <div className="mdl-button mdl-js-button mdl-js-ripple-effect mdl-button--accent mdl-button--raised button">
          {label_btn}
        </div>
        <div className="help-text">{label_remove}</div>
      </div>
    </Grid>
  )
}

ExtraService.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.number.isRequired,
    suggested: PropTypes.bool,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    realvalue: PropTypes.number.isRequired,
  }).isRequired,
  siblingNumber: PropTypes.number.isRequired,
  selectedExtraServices: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelectExtraService: PropTypes.func.isRequired,
}
