export const LOAD_CATALOGS = 'LOAD_CATALOGS'
export const SET_CATALOGS = 'SET_CATALOGS'
export const CHOOSE_CATALOG = 'CHOOSE_CATALOG'
export const LOAD_NATIONS = 'LOAD_NATIONS'
export const SET_NATIONS = 'SET_NATIONS'
export const CHOOSE_NATION = 'CHOOSE_NATION'
export const CHOOSE_RESORT_THEME = 'CHOOSE_RESORT_THEME'
export const LOAD_WEB_GROUPS = 'LOAD_WEB_GROUPS'
export const SET_WEB_GROUPS = 'SET_WEB_GROUPS'
export const CHOOSE_WEB_GROUP = 'CHOOSE_WEB_GROUP'
export const LOAD_PACKAGES = 'LOAD_PACKAGES'
export const SET_PACKAGES = 'SET_PACKAGES'
export const CHOOSE_PACKAGE = 'CHOOSE_PACKAGE'
export const LOAD_DEPARTURE_SERVICES = 'LOAD_DEPARTURE_SERVICES'
export const SET_DEPARTURE_SERVICES = 'SET_DEPARTURE_SERVICES'
export const CHOOSE_DEPARTURE_SERVICE = 'CHOOSE_DEPARTURE_SERVICE'
export const LOAD_STRUCTURES = 'LOAD_STRUCTURES'
export const SET_STRUCTURES = 'SET_STRUCTURES'
export const CHOOSE_STRUCTURE = 'CHOOSE_STRUCTURE'
export const LOAD_PROGRAM_TYPOLOGIES = 'LOAD_PROGRAM_TYPOLOGIES'
export const SET_PROGRAM_TYPOLOGIES = 'SET_PROGRAM_TYPOLOGIES'
export const CHOOSE_PROGRAM_TYPOLOGY = 'CHOOSE_PROGRAM_TYPOLOGY'
export const SET_ERRORS = 'SET_ERRORS'
export const RESET = 'RESET'
export const RESET_ALL_BUT_NATIONS = 'RESET_ALL_BUT_NATIONS'
export const INIT = 'INIT'
export const INIT_ALL = 'INIT_ALL'

export function init(
  catalogs = [],
  catalog = null,
  nations = [],
  nation = null,
  webGroups = [],
  webGroup = null,
  loadingPackages = false,
  resortTheme = ''
) {
  // console.info('action init', {catalogs, catalog, nations, nation, webGroup, webGroups, loadingPackages})
  return {
    type: INIT,
    catalogs,
    catalog,
    nations,
    nation,
    webGroups,
    webGroup,
    loadingPackages,
    resortTheme,
  }
}

export function initAll(
  catalogs = [],
  catalog = null,
  nations = [],
  nation = null,
  webGroups = [],
  webGroup = null,
  packages = [],
  choosenPackage = null,
  departureServices = [],
  departureService = null,
  structures = [],
  structure = null,
  programTypologies = [],
  programTypology = null
) {
  return {
    type: INIT_ALL,
    catalogs,
    catalog,
    nations,
    nation,
    webGroups,
    webGroup,
    packages,
    package: choosenPackage,
    departureServices,
    departureService,
    structures,
    structure,
    programTypologies,
    programTypology,
  }
}

export function reset() {
  return {type: RESET}
}

export function resetAllButNations() {
  return {type: RESET_ALL_BUT_NATIONS}
}

export function setErrors(errors) {
  return {type: SET_ERRORS, errors}
}

//Resort Theme
export function chooseResortTheme(resortTheme, loadWebGroups = false) {
  return {type: CHOOSE_RESORT_THEME, resortTheme, loadWebGroups}
}

// Catalogs
export function loadCatalogs() {
  return {type: LOAD_CATALOGS}
}
export function setCatalogs(catalogs = []) {
  return {type: SET_CATALOGS, catalogs}
}
export function chooseCatalog(catalog, loadNations = false) {
  return {type: CHOOSE_CATALOG, catalog, loadNations}
}

// Nations
export function loadNations() {
  return {type: LOAD_NATIONS}
}
export function setNations(nations = []) {
  return {type: SET_NATIONS, nations}
}
export function chooseNation(nation, loadWebGroups = false) {
  return {type: CHOOSE_NATION, nation, loadWebGroups}
}
// WebGroups
export function loadWebGroups() {
  // console.log('action loadWebGroups')
  return {type: LOAD_WEB_GROUPS}
}
export function setWebGroups(webGroups = []) {
  // console.log('action setWebGroups', {webGroups})
  return {type: SET_WEB_GROUPS, webGroups}
}
export function chooseWebGroup(webGroup, loadPackages = false) {
  // console.log('action choose web group', {webGroup, loadPackages})
  return {type: CHOOSE_WEB_GROUP, webGroup, loadPackages}
}
// Packages
export function loadPackages() {
  // console.log('action loadPackages')
  return {type: LOAD_PACKAGES}
}
export function setPackages(packages = []) {
  // console.log('action setPackages', {packages})
  return {type: SET_PACKAGES, packages}
}
export function choosePackage(
  choosenPackage,
  loadDepartureServices = false,
  loadStructures = false,
  loadProgramTypologies = false
) {
  return {
    type: CHOOSE_PACKAGE,
    package: choosenPackage,
    loadDepartureServices,
    loadStructures,
    loadProgramTypologies,
  }
}
// Departure services
export function loadDepartureServices() {
  return {type: LOAD_DEPARTURE_SERVICES}
}
export function setDepartureServices(departureServices = []) {
  return {type: SET_DEPARTURE_SERVICES, departureServices}
}
export function chooseDepartureService(departureService) {
  return {type: CHOOSE_DEPARTURE_SERVICE, departureService}
}
// Structures
export function loadStructures() {
  return {type: LOAD_STRUCTURES}
}
export function setStructures(structures = []) {
  return {type: SET_STRUCTURES, structures}
}
export function chooseStructure(structure, loadWebGroups = false) {
  return {type: CHOOSE_STRUCTURE, structure, loadWebGroups}
}
// ProgramTypologies
export function loadProgramTypologies() {
  return {type: LOAD_PROGRAM_TYPOLOGIES}
}
export function setProgramTypologies(programTypologies = []) {
  return {type: SET_PROGRAM_TYPOLOGIES, programTypologies}
}
export function chooseProgramTypology(programTypology) {
  return {type: CHOOSE_PROGRAM_TYPOLOGY, programTypology}
}
