import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import LanguageLvlSelector, {languageLevels} from '../LanguageLevelSelector'
import {languages} from './SchoolInformationUtils'

const useStyles = makeStyles(() => ({
  textField8: {
    width: '62.666667%',
    marginLeft: 0,
    marginRight: 0,
  },
  select: {
    width: 400,
  },
  menu: {
    width: 400,
  },
  languageRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

export default function SchoolInformation(props) {
  const classes = useStyles()
  const {
    // handlers
    onChange,
    onCheckboxChange,
    // school state
    schoolName,
    schoolAddress,
    schoolClass,
    schoolGrade,
    language1,
    languageLvl1,
    language2,
    languageLvl2,
    language3,
    languageLvl3,
    language4,
    languageLvl4,
    language5,
    languageLvl5,
  } = props
  const errors = props.errors || {}
  const [otherLanguage, setOtherLanguage] = useState(!!language5)
  return (
    <Grid container className="box-light">
      <Grid item xs={12}>
        <FormLabel component="legend">
          Nome dell'istituto<sup>*</sup>
        </FormLabel>
        <TextField
          id="multiline-flexible"
          value={schoolName}
          className={classes.textField8}
          margin="normal"
          onChange={onChange('schoolName')}
          error={errors.schoolName}
          helperText={errors.schoolName ? 'Obbligatorio' : ''}
        />
      </Grid>

      <Grid item xs={12}>
        <FormLabel component="legend">
          Indirizzo scolastico-percorso di studio<sup>*</sup>
        </FormLabel>
        <TextField
          id="multiline-flexible"
          value={schoolAddress}
          className={classes.textField8}
          margin="normal"
          onChange={onChange('schoolAddress')}
          error={errors.schoolAddress}
          helperText={errors.schoolAddress ? 'Obbligatorio' : ''}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">
            Classe frequentata nell’anno scolastico attualmente in corso
            <sup>*</sup>{' '}
          </FormLabel>
          <TextField
            id="school-class"
            select
            className={classes.select}
            value={schoolClass || ''}
            onChange={onChange('schoolClass')}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            error={errors.schoolClass}
            helperText={errors.schoolClass ? 'Obbligatorio' : ''}
            margin="normal">
            <MenuItem value={1}>1° Classe</MenuItem>
            <MenuItem value={2}>2° Classe</MenuItem>
            <MenuItem value={3}>3° Classe</MenuItem>
            <MenuItem value={4}>4° Classe</MenuItem>
            <MenuItem value={5}>5° Classe</MenuItem>
          </TextField>
        </FormControl>
      </Grid>

      {/*
      <Grid item xs={12}>
        <FormLabel component="legend">
          {//Media finale anno scolastico 2020/2021<sup>*</sup>}
          Media finale anno scolastico 2021/2022
        </FormLabel>
        <TextField
          id="multiline-flexible"
          value={schoolGrade}
          className={classes.textField8}
          margin="normal"
          onChange={onChange('schoolGrade')}
          error={errors.schoolGrade}
          helperText={errors.schoolGrade ? 'Obbligatorio' : ''}
        />
      </Grid>
          */}

      <Grid item xs={12}>
        <FormControl
          error={
            errors.language1 ||
            errors.language2 ||
            errors.language3 ||
            errors.language4 ||
            errors.language5
          }
          component="fieldset"
          className={classes.formControl}>
          <FormLabel component="legend">
            Lingua straniera studiata<sup>*</sup>
          </FormLabel>
          <FormGroup>
            <div className={classes.languageRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!language1}
                    onChange={onCheckboxChange('language1')}
                    value={languages.INGLESE}
                  />
                }
                label="Inglese"
              />
              <LanguageLvlSelector
                value={languageLvl1}
                error={errors.languageLvl1}
                handler={onChange('languageLvl1')}
                visible={!!language1}
              />
            </div>
            <div className={classes.languageRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!language2}
                    onChange={onCheckboxChange('language2')}
                    value={languages.SPAGNOLO}
                  />
                }
                label="Spagnolo"
              />
              <LanguageLvlSelector
                value={languageLvl2}
                error={errors.languageLvl2}
                handler={onChange('languageLvl2')}
                visible={!!language2}
              />
            </div>
            <div className={classes.languageRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!language3}
                    onChange={onCheckboxChange('language3')}
                    value={languages.FRANCESE}
                  />
                }
                label="Francese"
              />
              <LanguageLvlSelector
                value={languageLvl3}
                error={errors.languageLvl3}
                handler={onChange('languageLvl3')}
                visible={!!language3}
              />
            </div>
            <div className={classes.languageRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!language4}
                    onChange={onCheckboxChange('language4')}
                    value={languages.TEDESCO}
                  />
                }
                label="Tedesco"
              />
              <LanguageLvlSelector
                value={languageLvl4}
                error={errors.languageLvl4}
                handler={onChange('languageLvl4')}
                visible={!!language4}
              />
            </div>
            <div className={classes.languageRow}>
              <FormControlLabel
                control={
                  <React.Fragment>
                    <Checkbox
                      checked={otherLanguage}
                      onChange={event => setOtherLanguage(event.target.checked)}
                      value={languages.ALTRO}
                    />
                    {otherLanguage && (
                      <TextField
                        id="language5"
                        value={language5}
                        className={classes.textField11}
                        margin="normal"
                        // label="Altro"
                        placeholder="Altro"
                        onChange={onChange('language5')}
                      />
                    )}
                  </React.Fragment>
                }
                label={otherLanguage ? '' : 'Altro'}
              />
              <LanguageLvlSelector
                value={languageLvl5}
                error={errors.languageLvl5}
                handler={onChange('languageLvl5')}
                visible={!!language5}
              />
            </div>
          </FormGroup>
          <FormHelperText>
            {[
              errors.language1,
              errors.language2,
              errors.language3,
              errors.language4,
              errors.language5,
            ]
              .filter(Boolean)
              .join(', ') || ''}
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  )
}

SchoolInformation.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  schoolName: PropTypes.string,
  schoolAddress: PropTypes.string,
  schoolClass: PropTypes.number,
  schoolGrade: PropTypes.string,
  language1: PropTypes.string,
  languageLvl1: PropTypes.oneOf([...Object.values(languageLevels), '']),
  language2: PropTypes.string,
  languageLvl2: PropTypes.oneOf([...Object.values(languageLevels), '']),
  language3: PropTypes.string,
  languageLvl3: PropTypes.oneOf([...Object.values(languageLevels), '']),
  language4: PropTypes.string,
  languageLvl4: PropTypes.oneOf([...Object.values(languageLevels), '']),
  language5: PropTypes.string,
  languageLvl5: PropTypes.oneOf([...Object.values(languageLevels), '']),
}
