import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
// import MenuList from '@material-ui/core/MenuList'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  // small: {
  //   width: theme.spacing(3),
  //   height: theme.spacing(3),
  // },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}))

export default function TopHeaderAvatar({onLogout, userData}) {
  const classes = useStyles()
  const {firstName, lastName} = userData

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setAnchorEl(null)
    onLogout()
  }

  const initials =
    (firstName && firstName.length > 0 ? firstName.charAt(0) : '') +
    (lastName && lastName.length > 0 ? lastName.charAt(0) : '')

  return (
    <div className={classes.root}>
      <IconButton aria-label="menu" onClick={handleClick} className='avatar-user'>
        {/*<Avatar alt={`${firstName} ${lastName}`} className={classes.large}>*/}
        <Avatar alt={`${firstName} ${lastName}`}>
          {initials.toUpperCase()}
        </Avatar>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'center',
        // }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  )
}

TopHeaderAvatar.propTypes = {
  onLogout: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
}
