import React from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    textField4: {
        width: '32.333333%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    textField6: {
        width: '46.666%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    menu: {
        width: 200,
    },
}))

export default function Coupon(props) {
    const classes = useStyles()
    const { value, onChange } = props

    return <div></div>
    /* Rimosso come da richiesta di Gloria Capretta del 16/12/2021
    return <div className="box-light boxed">
        <Typography variant="h6">
        Codice sconto / codice voucher / codice azienda convenzionata (opzionale)
    </Typography>
        <Typography variant="body1">
        Indichi l'eventuale codice sconto/voucher a sua disposizione, l'importo indicato verrà scalato dal saldo dovuto
    </Typography>
        <TextField
            id="media-anno"
            //required
            label="Codice sconto/voucher"
            className={clsx(classes.textField6, 'select', 'col-xs-12')}
            value={value || ''}
            onChange={onChange}
            margin="normal"
        />
    </div>
    */
}

Coupon.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
}