import React, {useEffect, useState} from 'react';

const CountdownContext = React.createContext();

const CountdownProvider = ({children}) => {
    const [timeLeft, setTimeLeft] = useState(40 * 60); //40 minuti

    useEffect(() => {
        if (timeLeft <= 0) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return (
        <CountdownContext.Provider value={timeLeft}>
            {children}
        </CountdownContext.Provider>
    );
};
export {CountdownContext, CountdownProvider}
