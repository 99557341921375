import React, {useContext} from 'react'
import {AppContext} from '../../../contexts/AppContext'
import DisabilityUI from './Disability'

export default function Disability() {
  const [state, setState] = useContext(AppContext)
  const {
    step3Handicap,
    step3Helper,
    step3Disabilities,
    step3DisabilitiesService,
    step3NumberHelpers,
    step3DisabilityNotes,
    step3TypeHelpers,
    errors,
    applicationType,
  } = state
  const handleChange = label => event => {
    const {value} = event.target
    setState(state => ({...state, [label]: value}))
  }
  const handleFlagChange = label => event => {
    const {value} = event.target
    if (value === 'si') {
      setState(state => ({...state, [label]: value}))
    } else {
      switch (label) {
        case 'step3Handicap':
          setState(state => ({
            ...state,
            step3Handicap: value,
            step3Helper: 'no',
            step3Disabilities: '',
            step3NumberHelpers: null,
            step3TypeHelpers: '',
          }))
          break
        case 'step3Helper':
          setState(state => ({
            ...state,
            step3Helper: value,
            step3NumberHelpers: null,
            step3TypeHelpers: '',
          }))
          break
        default:
          setState(state => ({...state, [label]: value}))
          break
      }
    }
  }
  return (
    <DisabilityUI
      errors={errors}
      step3Handicap={step3Handicap}
      step3Helper={step3Helper}
      step3Disabilities={step3Disabilities}
      step3DisabilitiesService={step3DisabilitiesService}
      step3NumberHelpers={step3NumberHelpers}
      step3DisabilityNotes={step3DisabilityNotes}
      step3TypeHelpers={step3TypeHelpers}
      onChange={handleChange}
      onFlagChange={handleFlagChange}
      assitanceExtraQuestions={applicationType === 'ada'}
    />
  )
}
