import React, {useContext} from 'react'
import {participationsLookup} from '../../specificUtils/configs'
import {AppContext} from '../../../contexts/AppContext'
import useAuthentication from 'hooks/useAuthentication'
import useContextController from '../../../hooks/useContextController'
import StepCustomerDataUI from './StepCustomerData'
import {birthNationalityType} from './utils'
import useApi from "../../../hooks/useApi";

export default function StepCustomerData() {
    const [state, setState] = useContext(AppContext)
    const {
        resetAllParticipantsRelated,
        loadInps,
        loadBonus,
    } = useContextController()
    const {checkIsUserLogged} = useAuthentication()
    const {userExistsByFiscalCode} = useApi()
    const isUserLogged = checkIsUserLogged()
    const saveValue = async (name, value) => {
        if (name === 'step1ParentFiscalCode') {
            if (value.length === 16) {
                //Codice fiscale completo
                //Controlo se esiste già un member con questo cf
                const res = await userExistsByFiscalCode(value);
                saveValue('memberExists', res.result);
            }
        }
        const newState = {
            [name]: value,
        }
        if (state.step1SameResidence) {
            if (name === 'step1ParentStreetAddress') {
                newState['step1ChildStreetAddress'] = value
            }
            if (name === 'step1ParentCAP') {
                newState['step1ChildCAP'] = value
            }
            if (name === 'step1ParentProv') {
                newState['step1ChildProv'] = value
            }
            if (name === 'step1ParentResidenceCity') {
                newState['step1ChildResidenceCity'] = value
            }
            if (name === 'step1ParentRegion') {
                newState['step1ChildRegion'] = value
            }
        }
        setState(state => ({...state, ...newState}))
    }
    const handleEventChange = name => event => {
        const value = event && event.target ? event.target.value : null
        saveValue(name, value)
    }
    const handleValueChange = name => value => {
        if (
            typeof value === 'string' ||
            typeof value === 'number' ||
            typeof value === 'boolean' ||
            value instanceof Date
        ) {
            saveValue(name, value)
        } else {
            // value is not a value, is an event
            handleEventChange(name)(value)
        }
    }
    const handlePasswordChange = password => setState(state => ({...state, step1Password: password}))

    const handleParticipationChange = value => {
        const {step2Participation: oldValue} = state
        if (value !== oldValue) {
            resetAllParticipantsRelated()
        }
        const newState = {
            step2Participation: value,
        }
        if (value === participationsLookup.INPSIEME
            || value === participationsLookup.INPSNUOVO) {
            //partecipo al bando inps
            loadInps()
        } else if (value === participationsLookup.PRIVATO) {
            //partecipo privatamente
            loadBonus()
        } else if (value === participationsLookup.AZIENDA_CONVENZIONATA) {
            //azienda convenzionata o welfare
            loadBonus()
            newState.step2WelfareType = value
        }
        setState(state => ({...state, ...newState}))
    }

    const handleBirthNationalityChange = (label, userType) => value => {
        let newState = {
            [label]: value,
        }
        // eslint-disable-next-line default-case
        switch (userType) {
            case birthNationalityType.PARENT:
                newState = {
                    ...newState,
                    step1ParentBirthCity: '',
                    step1ParentBirthProv: '',
                    step1ParentBirthNation: '',
                    step1ParentForeignBirthCity: '',
                }
                break
            case birthNationalityType.CHILD:
                newState = {
                    ...newState,
                    step1ChildBirthCity: '',
                    step1ChildBirthProv: '',
                    step1ChildBirthNation: '',
                    step1ChildForeignBirthCity: '',
                }
                break
        }
        setState(state => ({...state, ...newState}))
    }

    const handleFlagChange = label => event => {
        const val = event.target.value;
        if (val === '0') {
            setState(state => ({
                ...state,
                step1ChildDocType: '',
                step1ChildDocNumber: '',
                step1ChildDocCountry: '',
                step1ChildDocEmission: null,
                step1ChildDocExpiration: null,
            }))
        }

        setState(state => ({
            ...state,
            flDocInserted: val
        }))
    }

    const handleParentBirthCityChange = value => {
        setState(state => ({
            ...state,
            step1ParentBirthCity: value,
            step1ParentBirthProv: value.car,
        }))
    }

    const handleParentResidenceCityChange = value => {
        const city = value == null ? '' : value.name
        const newState = {
            step1ParentResidenceCityObj: value,
            step1ParentResidenceCity: city,
        }
        if (state.step1SameResidence) {
            newState.step1ChildResidenceCityObj = value
            newState.step1ChildResidenceCity = city
        }
        setState(state => ({...state, ...newState}))
    }

    const handleChildResidenceCityChange = value => {
        const city = value == null ? '' : value.name
        setState(state => ({
            ...state,
            step1ChildResidenceCityObj: value,
            step1ChildResidenceCity: city,
        }))
    }

    const handleChildBirthCityChange = value => {
        setState(state => ({
            ...state,
            step1ChildBirthCity: value,
            step1ChildBirthProv: value.car,
        }))
    }

    const handleSameResidenceChange = () => {
        const wasSameResidence = state.step1SameResidence
        if (wasSameResidence) {
            setState(state => ({
                ...state,
                step1SameResidence: false,
                step1ChildStreetAddress: '',
                step1ChildCAP: '',
                step1ChildProv: '',
                step1ChildRegion: '',
                step1ChildResidenceCity: '',
                step1ChildResidenceCityObj: null,
            }))
        } else {
            setState(state => ({
                ...state,
                step1SameResidence: true,
                step1ChildStreetAddress: state.step1ParentStreetAddress,
                step1ChildCAP: state.step1ParentCAP,
                step1ChildProv: state.step1ParentProv,
                step1ChildRegion: state.step1ParentRegion,
                step1ChildResidenceCity: state.step1ParentResidenceCity,
                step1ChildResidenceCityObj: state.step1ParentResidenceCityObj,
            }))
        }
    }
    const handleContributoInps = event => {
        const {value, checked} = event.target
        const val = value.toString();
        let step1Questions = state.step1Questions;
        const index = step1Questions.indexOf(val);

        if (checked) {
            if (val.toLowerCase() === 'no') {
                step1Questions = [];
            } else {
                // Cancella solo l'elemento "no" all'interno dell'array
                const maiIndex = step1Questions.indexOf('No');
                if (maiIndex !== -1) {
                    step1Questions.splice(maiIndex, 1);
                }
            }
            if (index === -1) {
                step1Questions.push(val);
            }
        } else {
            if (index !== -1) {
                step1Questions.splice(index, 1);
            }
        }
        setState(state => ({
            ...state,
            step1Questions: step1Questions,
            step1NeverQuestion: checked ? false : state.step1NeverQuestion,
        }))

        /*switch (value) {
            case '2019':
                setState(state => ({
                    ...state,
                    step12019Question: checked,
                    step1NeverQuestion: checked ? false : state.step1NeverQuestion,
                }))
                break
            case '2020':
                setState(state => ({
                    ...state,
                    step12020Question: checked,
                    step1NeverQuestion: checked ? false : state.step1NeverQuestion,
                }))
                break
            case '2021':
                setState(state => ({
                    ...state,
                    step12021Question: checked,
                    step1NeverQuestion: checked ? false : state.step1NeverQuestion,
                }))
                break
            default:
                setState(state => ({
                    ...state,
                    step12019Question: checked ? false : state.step12019Question,
                    step12020Question: checked ? false : state.step12020Question,
                    step12021Question: checked ? false : state.step12021Question,
                    step1NeverQuestion: checked,
                }))
                break
        }*/
    }

    return (
        <StepCustomerDataUI
            state={state}
            isUserLogged={isUserLogged}
            onEventChange={handleEventChange}
            onValueChange={handleValueChange}
            onParticipationChange={handleParticipationChange}
            onPasswordChange={handlePasswordChange}
            onBirthNationalityChange={handleBirthNationalityChange}
            onParentBirthCityChange={handleParentBirthCityChange}
            onChildBirthCityChange={handleChildBirthCityChange}
            onParentResidenceCityChange={handleParentResidenceCityChange}
            onChildResidenceCityChange={handleChildResidenceCityChange}
            onSameResidenceChange={handleSameResidenceChange}
            onPrivacyChange={handleValueChange('step4Privacy')}
            onContributoInps={handleContributoInps}
            onChangeFlDocInserted={handleFlagChange}
        />
    )
}
