import React, {useState} from 'react'

const getAppContextInitialState = props => ({
  // interni
  division: props.division,
  applicationType: props.applicationType,
  originPracticeId: props.originPracticeId,
  qsToken: props.qsToken,
  isSubmitting: false,
  activeStep: 0,
  practiceId: null,
  step1country: null,
  step1countrylabel: null,
  step1pack: 0,
  step1packlabel: null,
  step1SameResidence: false,
  step1turns: 0,
  step1turnslabel: null,
  step2bonuses: [],
  step2InpsServiceFlag: false,
  extraServices: [],
  step2WelfareType: null,
  inpsServices: [],
  step4AlternativePackageEnabled: false,
  fetchingExtraServices: false,
  precompiling: props.originPracticeId && props.qsToken,

  // esterni (API)
  id: null,
  authCode: null,
  productManagementFlags: {},

  // step 1
  step1DepartureServiceIds: null,
  step1DepartureServiceItems: null,
  step1ParentSurname: '',
  step1ParentName: '',
  comuni: [],
  step1ParentNationality: 'Italiana',
  step1ChildNationality: 'Italiana',
  step1ParentBirthNation: '',
  step1ChildBirthNation: '',
  step1ParentForeignBirthCity: '',
  step1ChildForeignBirthCity: '',
  step1ParentBirthCity: null,
  step1ParentBirthDate: null,
  step1ParentBirthProv: '',
  step1ParentGender: '',
  step1ParentFiscalCode: '',
  step1ParentStreetAddress: '',
  step1ParentResidenceCity: '',
  step1ParentResidenceCityObj: null,
  step1ParentCAP: '',
  step1ParentProv: '',
  step1ParentRegion: '',
  step1ParentEmail: '',
  step1ParentPhone: '',
  step1ParentPhoneAlt: '',
  step1ChildSurname: '',
  step1ChildName: '',
  step1ChildBirthCity: null,
  step1ChildBirthProv: '',
  step1ChildBirthDate: null,
  step1ChildGender: '',
  step1ChildFiscalCode: '',
  step1ChildStreetAddress: '',
  step1ChildResidenceCity: '',
  step1ChildResidenceCityObj: null,
  step1ChildCAP: '',
  step1ChildProv: '',
  step1ChildRegion: '',
  step1ChildEmail: '',
  step1ChildPhone: '',
  step1ChildPhoneAlt: '',
  step1ChildDocType: '',
  step1ChildDocNumber: '',
  step1ChildDocCountry: '',
  step1ChildDocEmission: null,
  step1ChildDocExpiration: null,
  step1Questions: [],
  step12017Question: false,
  step12018Question: false,
  step12019Question: false,
  step12020Question: false,
  step12021Question: false,
  step1NeverQuestion: false,
  step1Participants: [],
  step1IceFullName: "",
  step1IceCellPhone: "",

  // step 2
  step2Participation: '',
  step2InpsContributeQuantity: '',
  step2InpsServiceId: '',
  step2Grade: '',
  step2BonusType: '',
  step2BonusRequested: false,
  step2BonusId: null,
  step2BonusSurname: '',
  step2BonusName: '',
  step2AziendaConvenzionataNote: '',
  fixedPracticeInpsNumber: '',
  variablePracticeInpsNumber: '',

  // step 3
  step3Handicap: '',
  step3Helper: '',
  step3TypeHelpers: '',
  step3Intolerance: '',
  step3IntoleranceText: '',
  step3IntoleranceType: '',
  // step3MedicalCert: '',
  step3NoteRoommate: '',
  step3TeacherFullName: '',
  step3RoommateName: '', // @stefano: questi campi sono deprecated per i form non HSP
  step3RoommateSurname: '', // @stefano: questi campi sono deprecated per i form non HSP
  step3NoteLeaving: '',
  step3Disabilities: '',
  step3DisabilitiesService: '',
  step3HasAdditionalNeeds: '',
  step3AdditionalNeeds: '',
  step3CodiceDocente: '', //utilizzato per form docenti
  packageId: null, //utilizzato per form docenti
  step3DisabilityNotes: '',
  step3NumberHelpers: null,
  step3Diet: '',
  step3DietType: '',
  step3DietText: '',

  // hsp school
  schoolName: '',
  schoolAddress: '',
  schoolClass: undefined,
  schoolGrade: '',
  language1: '',
  languageLvl1: '',
  language2: '',
  languageLvl2: '',
  language3: '',
  languageLvl3: '',
  language4: '',
  languageLvl4: '',
  language5: '',
  languageLvl5: '',

  // step 4
  step4Nation: null,
  step4PackageGroup: null,
  step4Turn: null,
  step4Package: null,
  step4DepartureServiceItems: '',
  step4DepartureServiceIds: '',
  step4ExtraServices: [],
  step4Structure: null,
  step4Privacy: false,
  step4Marketing: '',
  step4Profilation: '',
  step4Profilation2: '',
  step4Exclusivity: false,

  // step 5
  step5NewReservation: false,

  // confirmations
  step1Confirmed: false,
  step2Confirmed: false,
  step3Confirmed: false,
  step4Confirmed: false,
  step1Time: '',
  step2Time: '',
  step3Time: '',
  step4Time: '',
  step1Password: '',
  memberExists: false, //Usato per mostrare i campi PW

  //button next
  buttonDisabled: false,

  // Altro
  clubRegistration: false,

  isDouble: false,
})

const AppContext = React.createContext([{}, () => {}])

const AppProvider = props => {
  const [state, setState] = useState(getAppContextInitialState(props))

  return (
    <AppContext.Provider value={[state, setState]}>
      {props.children}
    </AppContext.Provider>
  )
}

export {AppContext, AppProvider, getAppContextInitialState}
