import React, {useContext} from 'react'
import StepBonusUI from './StepBonus'
import {AppContext} from '../../../contexts/AppContext'

export default function StepBonus() {
    const [state, setState] = useContext(AppContext)
    const handleEventChange = label => event => {
        const {value} = event.target
        console.log({[label]: value})
        setState(state => ({...state, [label]: value}))
    }
    const handleSimpleCheckboxChange = label => event => {
        const {checked} = event.target
        setState(state => ({...state, [label]: checked}))
    }
    const handleCheckboxChange = label => event => {
        const {checked, value} = event.target
        setState(state => ({...state, [label]: checked ? value : null}))
    }
    const handleBonusRequest = bonusType => event => {
        const {checked} = event.target
        const {step2bonuses} = state
        if (checked) {
            const selectedBonus = step2bonuses.find(
                item => item.bonustype && item.bonustype === bonusType
            )
            setState(state => ({
                ...state,
                step2BonusRequested: bonusType,
                step2BonusType: bonusType,
                step2BonusId: selectedBonus ? selectedBonus.value : null,
            }))
        } else {
            setState(state => ({
                ...state,
                step2BonusRequested: null,
                step2BonusType: bonusType,
                step2BonusId: '',
            }))
        }
    }

    return (
        <StepBonusUI
            state={state}
            onEventChange={handleEventChange}
            onSimpleCheckboxChange={handleSimpleCheckboxChange}
            onBonusRequest={handleBonusRequest}
            onCheckboxChange={handleCheckboxChange}
        />
    )
}
