import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

const DialogContext = createContext({})

export const useDialogContext = () => useContext(DialogContext)

export function DialogContextProvider({children}) {
  const [open, setOpen] = useState(false)

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  const closeCallback = useRef()

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (open === false && closeCallback.current) {
      closeCallback.current()
    }
  }, [open])

  const openDialog = async (title, content, button) => {
    setTitle(title)
    setContent(content)
    setOpen(true)

    return new Promise(resolve => {
      closeCallback.current = resolve
    })
  }

  return (
    <React.Fragment>
      <DialogContext.Provider value={{isOpen: open, openDialog}}>
        {children}
      </DialogContext.Provider>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
