import React, {useContext} from 'react'
import StepInformationUI from './StepInformation'
import {AppContext} from '../../../contexts/AppContext'

export default function StepInformation() {
    const [state, setState] = useContext(AppContext)
    const {
        step3NoteLeaving,
        step3NoteRoommate,
        step3RoommateSurname,
        step3RoommateName,
        step3AdditionalNeeds,
        step3HasAdditionalNeeds
    } = state
    const errors = state.errors || {}
    const handleChange = label => event => {
        const {value} = event.target
        setState(state => ({...state, [label]: value}))
    }

    return (
        <StepInformationUI
            onChange={handleChange}
            step3NoteLeaving={step3NoteLeaving}
            step3RoommateSurname={step3RoommateSurname}
            step3RoommateName={step3RoommateName}
            step3NoteRoommate={step3NoteRoommate}
            step3AdditionalNeeds={step3AdditionalNeeds}
            step3HasAdditionalNeeds={step3HasAdditionalNeeds}
            errors={errors}
        />
    )
}
