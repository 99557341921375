import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import TopHeader from '../TopHeader'
import DebugPanel from '../DebugPanel'
import {CheckCircle, Extension, Flight, Info, Stars, Train} from "@material-ui/icons";
import clsx from "clsx";
import GlobalAlertTimer from "../GlobalAlertTimer";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        // height: 60,
    },
    iconRoot: {
        backgroundColor: '#eaeaf0',
        padding: 8,
        borderRadius: '50%',
        color: '#9E9E9E',
    },
    iconActive: {
        color: '#3F51B5',
    },
    iconCompleted: {
        color: 'green',
    },
    chip: {
        margin: 2,
    },

    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

export default function StepperComponent(props) {
    const [fixedSidebar, setFixedSidebar] = useState(false)
    useEffect(() => {
        if (props.activeStep === 3) {
            const handleScroll = () => {
                if (window.scrollY > 140) {
                    return setFixedSidebar(true)
                }
                setFixedSidebar(false)
            }
            const handleResize = () => {
                if (window.innerWidth >= 900 && window.scrollY > 140) {
                    return setFixedSidebar(true)
                }
                setFixedSidebar(false)
            }

            window.addEventListener('scroll', handleScroll)
            window.addEventListener('resize', handleResize)

            return () => {
                window.removeEventListener('scroll', handleScroll)
                window.removeEventListener('resize', handleResize)
            }
        }
    }, [props.activeStep])

    const classes = useStyles()
    const {
        onNext,
        onBack,
        onReset,
        steps,
        customerCareDescription,
        customerCarePhone,
        getStepContent,
        stepsByName,
        Sidebar,
        activeStep,
        division,
        isSubmitting = false,
        isSubmitButtonDisabled,
        sendBookingButtonLabel = 'Invia Prenotazione',
        fetchingExtraServices = false,
        applicationType,
    } = props

    const CustomStepIcon = (props) => {
        const classes = useStyles();
        const {active, completed} = props;

        const stepIcons = {
            1: (division === '2') ? <Train/> : <Flight/>,
            2: <Stars/>,
            3: <Info/>,
            4: <Extension/>,
            5: <CheckCircle/>,
        };

        return (
            <div
                className={clsx(classes.iconRoot, {
                    [classes.iconActive]: active,
                    [classes.iconCompleted]: completed,
                })}
            >
                {stepIcons[String(props.icon)]}
            </div>
        );
    };

    let showCountdown = (applicationType === 'main');

    return (
        <div className="container-fluid">
            <TopHeader
                division={division}
                phone={customerCarePhone}
                rightTextRows={customerCareDescription}
            />
            <div className={classes.root + ' container v-divider-top'}>
                {showCountdown &&
                    <>
                        <br/>
                        <GlobalAlertTimer activeStep={activeStep}/>
                        <br/>
                    </>
                }
                <DebugPanel/>
                <br/>
                <Grid container spacing={3} className="form-container">
                    <Grid item xs={12} sm={12} className="step-nav-wrapper">
                        <Stepper
                            activeStep={activeStep}
                            alternativeLabel
                            className="step-nav">
                            {steps.map((label, idx) => {
                                return (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={CustomStepIcon}>{idx + 1} - {label}</StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={activeStep === stepsByName.RECAP ? 12 : 9}
                        className="box-form form-content">
                        <div>
                            {activeStep === steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>
                                        All steps completed
                                    </Typography>
                                    <Button onClick={onReset}>Reset</Button>
                                </div>
                            ) : (
                                <div>
                                    <div className={classes.instructions}>{getStepContent()}</div>
                                    <div className="footer-step-nav">
                                        <Button
                                            disabled={
                                                activeStep === 0 ||
                                                activeStep === stepsByName.RECAP ||
                                                isSubmitting
                                            }
                                            onClick={onBack}
                                            className={classes.backButton}>
                                            {activeStep === stepsByName.RECAP ? '' : 'Indietro'}
                                        </Button>
                                        {activeStep !== stepsByName.RECAP && (
                                            <Button
                                                disabled={
                                                    activeStep === stepsByName.RECAP - 1
                                                        ? isSubmitButtonDisabled || fetchingExtraServices
                                                        : isSubmitting
                                                }
                                                variant="contained"
                                                color={
                                                    activeStep === stepsByName.RECAP - 1
                                                        ? 'secondary'
                                                        : activeStep === stepsByName.RECAP
                                                            ? 'default'
                                                            : 'primary'
                                                }
                                                onClick={onNext}
                                                className={
                                                    activeStep === stepsByName.RECAP - 1
                                                        ? classes.button + ' btn-lg btn-reserve'
                                                        : activeStep === stepsByName.RECAP
                                                            ? classes.button + ' btn-success'
                                                            : classes.button
                                                }>
                                                {activeStep === stepsByName.RECAP - 1
                                                    ? sendBookingButtonLabel
                                                    : 'Avanti'}
                                                {isSubmitting && (
                                                    //aggiungere solo su step stepsByName.RECAP - 1
                                                    <React.Fragment>
                                                        {activeStep === stepsByName.RECAP - 1 && (
                                                            <small>> Attendi caricamento dati...</small>
                                                        )}
                                                        <CircularProgress
                                                            size={24}
                                                            className={classes.buttonProgress}
                                                        />
                                                    </React.Fragment>
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Grid>
                    {activeStep < stepsByName.RECAP && (
                        <Grid
                            item
                            xs={12}
                            sm={3}
                            className={`form-sidebar ${fixedSidebar && 'fixed'} div-${division}`}>
                            <Sidebar/>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    )
}

StepperComponent.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    activeStep: PropTypes.number.isRequired,
    division: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool,
    isSubmitButtonDisabled: PropTypes.bool.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
    customerCareDescription: PropTypes.arrayOf(PropTypes.string).isRequired,
    customerCarePhone: PropTypes.string.isRequired,
    getStepContent: PropTypes.func.isRequired,
    stepsByName: PropTypes.object.isRequired,
    Sidebar: PropTypes.elementType.isRequired,
    sendBookingButtonLabel: PropTypes.string,
    fetchingExtraServices: PropTypes.bool,
}
