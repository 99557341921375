import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Coupon from './Coupon'
import {bonusTypes} from '../../specificUtils/configs'
import Voucher from './Voucher'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

export default function StepBonusPrivate(props) {
  const {
    // division,
    step2bonuses,
    step2BonusRequested,
    step2BonusType,
    step2BonusSurname,
    step2BonusName,
    step2Coupon,
    onBonusRequest,
    onEventChange,
    step1ChildFiscalCode,
    step2VoucherCode,
    step2VoucherCodes,
  } = props
  const classes = useStyles()
  return (
    <React.Fragment>
      <div className="box-light boxed">
        <Grid container spacing={3} className="panel">
          <Grid item xs={12} sm={3} className="bonus-wrapper">
            <Typography variant="h6">Bonus</Typography>
            <img
              //src="https://t-ec.bstatic.com/static/img/genius/genius_trial/tier-gift/1c8d6deaaabcdc179a20aefe503b0374b6ff096a.png"
              src="https://giocamondo.it/step2_bonus.png"
              height={100}
              alt="bonus"
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            {step2bonuses.filter(({ bonustype }) =>
              [bonusTypes.FRATELLO, bonusTypes.AMICO].includes(bonustype)
            ).length > 0 ? (
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormGroup>
                    {step2bonuses.filter(
                      ({ bonustype }) => bonustype === bonusTypes.FRATELLO
                    ).length > 0 && (
                        <React.Fragment>
                          <Typography variant="h5">
                            Inserisci il nominativo del fratello/sorella che partirà
                            per un soggiorno Giocamondo
                            {/* {division === '1' ? ' Giocamondo Study ' : ' Giocamondo '} */}
                            {/* nel 2020 */}
                          </Typography>
                          <p>
                            Lo sconto <strong>Bonus Fratelli</strong> di € 25 verrà applicato alle prenotazioni di ciascun partecipante in
                            cui verrà selezionato.
                          </p>

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={step2BonusRequested === bonusTypes.FRATELLO}
                                onChange={onBonusRequest(bonusTypes.FRATELLO)}
                                value={bonusTypes.FRATELLO}
                              />
                            }
                            label="Voglio usufruire del Bonus Fratelli"
                          />
                        </React.Fragment>
                      )}
                    {step2bonuses.filter(
                      ({ bonustype }) => bonustype === bonusTypes.AMICO
                    ).length > 0 && (
                        <React.Fragment>
                          <hr />
                          <Typography variant="h5">
                            Inserisci il nominativo di un tuo/a amico/a 'Inps' che
                            partirà per un soggiorno Giocamondo
                      {/* {division === '1' ? ' Giocamondo Study ' : ' Giocamondo '} */}
                            {/* nel 2020 */}
                          </Typography>
                          <p>
                            Lo sconto <strong>Bonus Parto con un Amico</strong> verrà
                      applicato solo ai clienti privati ed andrà a scontare il
                      saldo dovuto.
                    </p>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={step2BonusRequested === bonusTypes.AMICO}
                                onChange={onBonusRequest(bonusTypes.AMICO)}
                                value={bonusTypes.AMICO}
                              />
                            }
                            label="Voglio usufruire del Bonus Parto con un amico"
                          />
                        <p>
                          <small>* I bonus selezionati saranno soggetti a verifica</small>
                        </p>
                        </React.Fragment>
                        
                      )}
                  </FormGroup>
                  {/* <FormHelperText>Be careful</FormHelperText> */}
                </FormControl>
              ) : (
                'Nessun bonus disponibile su questo pacchetto, clicca avanti per proseguire.'
              )}
            <br />
            {step2BonusType && (
              <React.Fragment>
                <TextField
                  id="cognome"
                  label="Cognome"
                  className={classes.textField}
                  value={step2BonusSurname}
                  onChange={onEventChange('step2BonusSurname')}
                  margin="normal"
                />
                <TextField
                  id="nome"
                  label="Nome"
                  className={classes.textField}
                  value={step2BonusName}
                  onChange={onEventChange('step2BonusName')}
                  margin="normal"
                />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </div>
      <br />
      <Coupon onChange={onEventChange('step2Coupon')} value={step2Coupon} />
      <Voucher
        step1ChildFiscalCode={step1ChildFiscalCode}
        onChange={onEventChange}
        value={step2VoucherCode}
        values={step2VoucherCodes}
      />
    </React.Fragment>

  )
}

StepBonusPrivate.propTypes = {
  division: PropTypes.string,
  step2bonuses: PropTypes.arrayOf(
    PropTypes.shape({
      bonustype: PropTypes.string.isRequired,
    })
  ),
  step2BonusRequested: PropTypes.bool,
  step2BonusType: PropTypes.any,
  step2BonusSurname: PropTypes.string,
  step2BonusName: PropTypes.string,
  step2Coupon: PropTypes.string,
  onBonusRequest: PropTypes.func.isRequired,
  onEventChange: PropTypes.func.isRequired,
}
