import React from 'react'
import {Alert, AlertTitle} from '@material-ui/lab'


export default function StepPaymentSuccess(props) {
    const {
        title = 'Grazie per aver scelto una vacanza studio o soggiorno estivo GIOCAMONDO STUDY',
        message = "Controlli la mail che ha appena ricevuto e verifichi che le informazioni inserite siano corrette. In caso contrario ci contatti telefonicamente allo 0736343440 oppure allo 0736336339"
    } = props

    return (
        <Alert severity="success">
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    )
}
