import React, {useContext} from 'react'
import PriceComponent from './Price'
import {AppContext} from '../../contexts/AppContext'
import {PackageSelectorContext} from '../../contexts/PackageSelectorContext'
import {SpendingContext} from '../../contexts/SpendingContext'
import useSpendingCalc from '../../hooks/useSpendingCalc'

export default function Price(props) {
  const [appState] = useContext(AppContext)
  const [psState] = useContext(PackageSelectorContext)
  const [spendingState] = useContext(SpendingContext)
  const {
    departureServices,
    departureService,
    structures,
    structure,
    programTypologies,
    programTypology,
  } = psState
  const {
    step2InpsServiceId,
    inpsServices,
    step2BonusId,
    step2bonuses,
    step2Coupon,
    step4ExtraServices,
    extraServices,
    applicationType,
  } = appState
  const {
    isLoadingPrice,
    priceToPay,
    totalPrice,
    visualTotalPrice,
    basicCoreCluster,
  } = spendingState
  const {
    getBasicPackagePrice,
    getDepartureServicePrice,
    getProgramTypologyPrice,
    getStructurePrice,
  } = useSpendingCalc()

  const basicCorePrice = getBasicPackagePrice() / 100

  let departureServicePrice = getDepartureServicePrice() / 100
  let departureCityLabel = ''
  if (departureService) {
    const city =
      departureServices.find(item => item.value === departureService) || {}
    departureCityLabel = city.label || ''
  }

  let structurePrice = getStructurePrice() / 100
  let structureLabel = ''
  if (structure) {
    const structureT = structures.find(s => s.value === structure) || {}
    structureLabel = structureT.label || ''
  }

  let programTypologyPrice =
    typeof getProgramTypologyPrice === 'function'
      ? getProgramTypologyPrice() / 100
      : 0
  let programTypologyLabel = ''
  if (programTypology) {
    const programTypologyFounded =
      programTypologies.find(pt => pt.value === programTypology) || {}
    programTypologyLabel = programTypologyFounded.label || ''
  }

  let contributoInpsLabel = ''
  // let contributoValue = 0
  if (step2InpsServiceId) {
    const contributoInps = inpsServices.find(
      is => `${is.value}` === step2InpsServiceId
    )
    if (contributoInps) {
      if (contributoInps.isVisualPrice) {
        contributoInpsLabel = `€${contributoInps.visualPriceAmount / 100}`
      } else {
        contributoInpsLabel = contributoInps.ispercentage
        ? `${contributoInps.realvalue / 100}%`
        : `€${contributoInps.realvalue / 100}`
        // contributoValue = contributoInps.ispercentage
        //   ? -((contributoInps.realvalue / 10000) * basicCorePrice)
        //   : contributoInps.realvalue / 100
      }
    }
  }

  let bonusLabel = ''
  let bonusValueLabel = ''
  // let bonusValue = 0
  if (step2BonusId) {
    const selectedBonus = step2bonuses.find(b => b.value === step2BonusId)
    if (selectedBonus) {
      bonusLabel = selectedBonus.label
      bonusValueLabel = selectedBonus.ispercentage
        ? `${selectedBonus.realvalue / 100}%`
        : `€${selectedBonus.realvalue / 100}`
      //calc bonus value
      // bonusValue = selectedBonus.ispercentage
      //   ? -((selectedBonus.realvalue / 10000) * basicCorePrice)
      //   : selectedBonus.realvalue / 100
    }
  }

  const extraServiceLabels = extraServices
    .map(({extraservices, ...bundle}) => ({
      ...bundle,
      es: extraservices.find(es => step4ExtraServices.includes(es.value)),
    }))
    .filter(bundle => Boolean(bundle.es))
    .map(bundle => {
      const es = bundle.es
      const price = es.ispercentage
        ? `${es.realvalue / 100}%`
        : `€${es.realvalue / 100}`
      return {
        id: es.value,
        title: bundle.title,
        value: `${es.label} ${price}`,
        label: es.label,
        realvalue: es.realvalue,
        isVisualPrice: es.isVisualPrice,
      }
    })

  const showInpsContribution =
    (applicationType === 'ada') ? false : contributoInpsLabel

  return (
    <PriceComponent
      isLoadingPrice={isLoadingPrice}
      priceToPay={priceToPay}
      totalPrice={totalPrice}
      visualTotalPrice={visualTotalPrice}
      packageId={psState.package}
      basicCorePrice={basicCorePrice}
      departureService={departureService}
      departureCityLabel={departureCityLabel}
      departureServicePrice={departureServicePrice}
      structure={structure}
      structureLabel={structureLabel}
      structurePrice={structurePrice}
      programTypology={programTypology}
      programTypologyLabel={programTypologyLabel}
      programTypologyPrice={programTypologyPrice}
      contributoInpsLabel={showInpsContribution}
      bonusValueLabel={bonusValueLabel}
      step2Coupon={step2Coupon}
      bonusLabel={bonusLabel}
      extraServiceLabels={extraServiceLabels}
      basicCoreCluster={basicCoreCluster}
      {...props}
    />
  )
}
