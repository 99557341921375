import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(2),
    },
    iconWrapper: {
        display: 'inline',
        margin: theme.spacing(0, 1, 0, 0),
    },
    textAction: {
        color: '#FC7200',
    },
    link: {
        color: 'inherit',
        textDecoration: 'none',
    },
    textBold: {
        fontWeight: theme.typography.fontWeightBold,
    },
}))

export default function MembershipFooter({source}) {
    const classes = useStyles()
    let label = "Oppure prosegui senza registrazione";
    let bolder = false;
    if (source === 'main') {
        label = "OPPURE SE NON SEI REGISTRATO PROSEGUI E REGISTRATI"
        bolder = true;
    }
    return (
        <div className={classes.root}>
            <a id="inserimentoDati" href="#inserimentoDati" className={classes.link}>
                <Typography
                    className={`${classes.iconWrapper} ${classes.textAction}`}
                    component="span">
                    &darr;
                </Typography>
                {bolder ?
                    <Typography component="span" className={classes.textBold}>
                        {label}
                    </Typography> :
                    <Typography component="span" variant="body2">
                        {label}
                    </Typography>}
            </a>
        </div>
    )
}
