import React, {useContext, useEffect} from 'react'
import StepPaymentWrapper from './StepPaymentWrapper'
import {participationsLookup} from '../../specificUtils/configs'
import {AppContext} from 'contexts/AppContext'
import {PackageSelectorContext} from 'contexts/PackageSelectorContext'
// import {PaymentProvider, PaymentContext} from 'contexts/PaymentContext'
import {PaymentContext} from 'contexts/PaymentContext'
import {SpendingContext} from 'contexts/SpendingContext'
import usePayment from 'hooks/usePayment'
import {getParams} from "../../../main/specificUtils/api";
import Loader from 'components/Loader'
import {useState} from 'react'

export default function StepPayment() {
  const [state] = useContext(AppContext)
  const [psState] = useContext(PackageSelectorContext)
  const [paymentState] = useContext(PaymentContext)
  const [spendingState] = useContext(SpendingContext)
  const {handleTipo, updatePayment, confirm} = usePayment()
  const {step2Participation} = state
  const [isBusy, setBusy] = useState(true)

  const selectedPackage =
    psState.packages.find(p => p.value === psState.package) || {}
  const {priceToPay, visualTotalPrice, priceDescription, priceError} = spendingState
  const {
    confirmed,
    paid,
    updatePaymentError,
    confirmError,
    payError,
  } = paymentState
  const hasError = !!updatePaymentError || !!confirmError || !!payError

  const fetchDomandaVincitoreInpsFlag = async () => {
    try {
      const keys = await getParams('11', ["formPrenotazione.domandaVincitoreINPS"])
      // console.info("keys", keys);
      if (keys['formPrenotazione.domandaVincitoreINPS']) {
        state['formPrenotazione.domandaVincitoreINPS'] = keys['formPrenotazione.domandaVincitoreINPS'].status
      }
      setBusy(false);
    } catch (e) {
      console.error(e);
    }
  }

  // scroll up on recap shown
  useEffect(() => {
    if (!!confirmed || !!paid || hasError) {
      window.scrollTo(0, 0)
    }
  }, [confirmed, paid, hasError])

  useEffect(() => {
    fetchDomandaVincitoreInpsFlag();
  }, []);

  return (isBusy ? (
    <Loader />
  ) : (
    <StepPaymentWrapper
      bonusRequested={state.step2BonusRequested}
      bonusType={state.step2BonusType}
      amount={priceToPay}
      amountDescription={priceDescription}
      participation={step2Participation}
      onTipo={handleTipo}
      onUpdatePayment={updatePayment}
      onConfirm={confirm}
      paymentState={paymentState}
      priceError={priceError}
      state={state}
    />
  ))
}

// export default function StepPayment() {
//   return (
//     <PaymentProvider>
//       <InnerStepPayment />
//     </PaymentProvider>
//   )
// }
