import React, {useContext} from 'react'
import {AppContext} from '../../contexts/AppContext'
import DietUI from './Diet'

export default function Diet() {
  const [state, setState] = useContext(AppContext)
  const {errors, step3Diet, step3DietType, step3DietText} = state
  const handleChange = label => event => {
    const {value} = event.target
    setState(state => ({...state, [label]: value}))
  }
  const handleFlagChange = label => event => {
    const {value} = event.target
    if (value === 'si') {
      setState(state => ({...state, [label]: value}))
    } else {
      switch (label) {
        case 'step3Diet':
          setState(state => ({
            ...state,
            step3Diet: value,
            step3DietType: '',
            step3DietText: '',
          }))
          break
        default:
          setState(state => ({...state, [label]: value}))
          break
      }
    }
  }
  return (
    <DietUI
      errors={errors}
      step3Diet={step3Diet}
      step3DietType={step3DietType}
      step3DietText={step3DietText}
      onChange={handleChange}
      onFlagChange={handleFlagChange}
    />
  )
}
