import React, {useContext} from 'react'
import StepInformationUI from './StepInformation'
import {AppContext} from '../../../contexts/AppContext'

export default function StepInformation() {
    const [state, setState] = useContext(AppContext)
    const {
        step3TeacherFullName,
        step3NoteLeaving,
        step3NoteRoommate,
        step3RoommateSurname,
        step3RoommateName,
        step3CodiceDocente,
        division,
        packageId
    } = state
    const errors = state.errors || {}
    const handleChange = label => event => {
        const {value} = event.target
        setState(state => ({...state, [label]: value}))
    }
    const onSaveStep3CodiceDocente = (item) => setState(state => ({...state, step3CodiceDocente: item}))

    return (
        <StepInformationUI
            onChange={handleChange}
            onSaveStep3CodiceDocente={onSaveStep3CodiceDocente}
            step3NoteLeaving={step3NoteLeaving}
            step3RoommateSurname={step3RoommateSurname}
            step3RoommateName={step3RoommateName}
            step3NoteRoommate={step3NoteRoommate}
            step3TeacherFullName={step3TeacherFullName}
            step3CodiceDocente={step3CodiceDocente}
            errors={errors}
            packageId={packageId}
            division={division}
        />
    )
}
