import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import ServerAutocomplete from './Autocomplete'
import useApi from '../hooks/useApi'

const useStyles = makeStyles(theme => ({
  autocompleteContainer: {
    marginTop: theme.spacing(3),
  },
}))

function ResidenceAutocomplete(props) {
  const classes = useStyles()
  const {getComuniByName} = useApi()
  const [availableCaps, setAvailableCaps] = useState([])

  const onCitySelect = evt => {
    const {cap: oldCap} = props
    const {cap = '', car: province, region} = evt
    const newAvailableCaps = cap.split(',')
    const newCap = newAvailableCaps.includes(oldCap)
      ? oldCap
      : newAvailableCaps[0]
    props.onCapChange(newCap)
    props.onCitySelect(evt)
    props.onRegionChange(region)
    props.onProvinceChange(province)
    setAvailableCaps(newAvailableCaps)
  }

  const {
    styles,
    streetAddress,
    streetAddressError,
    onStreetAddressChange,
    city,
    // cityPlaceholder,
    cityError,
    // onCityDelete,
    // onCitySelect,
    cap,
    capError,
    onCapChange,
    province,
    provinceError,
    onProvinceChange,
    disabled = false,
  } = props

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={4}>
        <TextField
          disabled={disabled}
          // id="standard-streetAddress"
          label="Via/Piazza *"
          value={streetAddress}
          error={!!streetAddressError && ' richiesto'}
          onChange={onStreetAddressChange}
          margin="normal"
          fullWidth
        />
      </Grid>
      {disabled ? (
        <Grid item xs={12} md={4}>
          <TextField
            disabled
            id="standard-city"
            label="Città *"
            value={city == null ? '' : city.name}
            margin="normal"
            fullWidth
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={4} className={classes.autocompleteContainer}>
          <ServerAutocomplete
            value={city}
            disabled={disabled}
            placeholder="Città *"
            onDelete={onCitySelect}
            onSelect={onCitySelect}
            getOptions={getComuniByName}
          />
          {!!cityError && (
            <FormHelperText className={styles.errorMessage}>
              richiesto*
            </FormHelperText>
          )}
        </Grid>
      )}
      <Grid item xs={12} md={2}>
        <TextField
          disabled={disabled}
          // id="standard-cap"
          label="CAP *"
          value={cap}
          error={!!capError && ' richiesto'}
          helperText={
            availableCaps.length > 1 &&
            'CAP multipli per la città selezionata, verificare che il dato attuale sia corretto.'
          }
          onChange={onCapChange}
          margin="normal"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          disabled={disabled}
          // id="standard-province"
          label="Prov *"
          onChange={onProvinceChange}
          value={province}
          error={!!provinceError && ' richiesto'}
          margin="normal"
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

ResidenceAutocomplete.propTypes = {
  styles: PropTypes.object,
  streetAddress: PropTypes.string,
  streetAddressError: PropTypes.string,
  onStreetAddressChange: PropTypes.func.isRequired,
  city: PropTypes.object,
  cityError: PropTypes.string,
  onCityDelete: PropTypes.func.isRequired,
  onCitySelect: PropTypes.func.isRequired,
  cap: PropTypes.string,
  capError: PropTypes.string,
  onCapChange: PropTypes.func.isRequired,
  province: PropTypes.string,
  provinceError: PropTypes.string,
  onProvinceChange: PropTypes.func.isRequired,
  onRegionChange: PropTypes.func.isRequired,
}

export default ResidenceAutocomplete
