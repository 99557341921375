import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import ExtraServicesItem from './ExtraServicesItemMobile'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    pricingTable: {
        padding: '3%',
        background: '#fff',
        borderRadius: '5px',
        //border: 'solid 3px #236fbf',
        boxShadow: '0 0px 40px #ccc',
        margin: '10px 0',
    },
    item: {
        margin: '8px 0',
    },
    header: {
        marginBottom: '24px',
    },
}))

export default function ExtraService(props) {
    const classes = useStyles()
    const {selectedExtraServices, onSelectExtraService, extraservice, isOptional} = props

    const {
        title,
        subtitle,
        description,
        extraservices,
        closureText,
    } = extraservice

    const filteredExtraServices = [...extraservices.filter(x => x.visibilityForm)];
    return (
        <div
            key={title + subtitle}
            className={clsx(classes.pricingTable, 'v-divider')}>
            {!isOptional && <div className={classes.header}>
                <Typography variant="h6">{title}</Typography>
                <Typography variat="body">{description}</Typography>
                {extraservice.mustSelectAtLeastOneItem && <Typography variant="h6" style={{color: 'red'}}>Devi selezionare almeno un servizio</Typography>}
            </div>}
            <div>
                {filteredExtraServices.length > 0
                    ? filteredExtraServices.map(xs => (
                        <div className={classes.item}>
                            <ExtraServicesItem
                                key={xs.value}
                                data={xs}
                                siblingNumber={filteredExtraServices.length - 1}
                                selectedExtraServices={selectedExtraServices}
                                onSelectExtraService={onSelectExtraService}
                            />
                        </div>
                    ))
                    : null}
            </div>
            {!!closureText && (
                <div>
                    <footer
                        className="extra-closure-text"
                        dangerouslySetInnerHTML={{
                            __html: closureText,
                        }}
                    />
                </div>
            )}
        </div>
    )
}

ExtraService.propTypes = {
    extraservice: PropTypes.shape({
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        closureText: PropTypes.string,
        extraservices: PropTypes.array,
    }).isRequired,
    selectedExtraServices: PropTypes.array.isRequired,
    onSelectExtraService: PropTypes.func.isRequired,
}
