import React, {useState} from 'react'
import PropTypes from 'prop-types'
import ButtonBase from '@material-ui/core/ButtonBase'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MembershipActionFooter from './MembershipActionFooter'
import Alert from '@material-ui/lab/Alert'
import PasswordComponent from './MembershipPasswordComponent'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
  checkbox: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  forgottenPasswordLabel: {
    marginRight: theme.spacing(4),
  },
  gridItemFlex: {
    display: 'flex',
  },
  gridItemFlexSpaceBetween: {
    justifyContent: 'space-between',
  },
  inputWrapper: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
  alert: {
    marginRight: theme.spacing(2),
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  textItalic: {
    fontStyle: 'italic',
  },
  textAction: {
    color: '#FC7200',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
}))

export default function MembershipLogin(props) {
  const {
    onLogin,
    onSignup,
    isSigningIn = false,
    hideForgottenPassword = false,
    error,
    source
  } = props
  // const errors = props.errors || {}
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [shouldSaveSession, setShouldSaveSession] = useState(false)

  let errorMessage
  if (error) {
    const {data = {}} = error.response || {}
    if (data.code === 401) {
      errorMessage = data.message || ''
    } else {
      errorMessage = 'Errore generico durante il tentativo di autenticazione.'
    }
  }

  function isLoginDisabled() {
    return (
      isSigningIn || email.trim().length === 0 || password.trim().length === 0
    )
  }

  function handleLoginClick() {
    onLogin(email, password, shouldSaveSession)
  }

  let title = "EFFETTUA IL LOGIN";
  let captionText = "Non hai ancora un account?";
  let onSecondaryAction = onSignup;
  let secondaryActionText= "Registrati ora";
  let subtitle;
  if(source === 'main'){
    title = 'SE SEI GIA\' REGISTRATO EFFETTUA L\'ACCESSO';
    captionText = onSecondaryAction = secondaryActionText = null;
    subtitle = 'Accedi in 2 clic e la maggior parte dei campi del primo step saranno automaticamente completati.';
  }

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.textBold}>
            {title}
          </Typography>
          {subtitle && <Typography variant="body2">
            {subtitle}
          </Typography>}
        </Grid>
        <Grid className={classes.gridItemFlex} item xs={12}>
          <TextField
            className={classes.inputWrapper}
            InputProps={{
              disableUnderline: true,
            }}
            onChange={event => {
              setEmail(event.target.value)
            }}
            value={email}
            placeholder="Email"
            type="email"
          />
          <PasswordComponent
            componentStyle={classes.inputWrapper}
            onChange={setPassword}
            value={password}
            placeholder="Password"
          />
        </Grid>
        <Grid
          className={`${classes.gridItemFlex} ${classes.gridItemFlexSpaceBetween}`}
          item
          xs={12}>
          <div>
            <Checkbox
              color="primary"
              className={classes.checkbox}
              onChange={event => {
                setShouldSaveSession(event.target.checked)
              }}
              value={shouldSaveSession}
            />
            <Typography display="inline" variant="caption">
              Ricordami
            </Typography>
          </div>
          {!hideForgottenPassword && (
            <div>
              <ButtonBase>
                <Typography
                  className={`${classes.forgottenPasswordLabel} ${classes.textItalic}`}
                  variant="caption">
                  <a
                    id="inserimentoDati"
                    href="https://club.giocamondo.it/password-recovery"
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer">
                    Password dimenticata?
                  </a>
                </Typography>
              </ButtonBase>
            </div>
          )}
        </Grid>
        {!!error && (
          <Grid item xs={12}>
            <Alert severity="error" className={classes.alert}>
              {errorMessage}
            </Alert>
          </Grid>
        )}
      </Grid>
      <MembershipActionFooter
        captionText={captionText}
        onPrimaryAction={handleLoginClick}
        onSecondaryAction={onSecondaryAction}
        isPrimaryActionDisabled={isLoginDisabled()}
        primaryActionText="ACCEDI"
        secondaryActionText={secondaryActionText}
        isPerformingPrimaryAction={isSigningIn}
      />
    </div>
  )
}

MembershipLogin.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onSignup: PropTypes.func.isRequired,
  isSigningIn: PropTypes.bool,
  hideForgottenPassword: PropTypes.bool,
  errors: PropTypes.object,
}
