import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Payment from 'components/Payment'
import {makeStyles} from '@material-ui/core/styles'
import {participationsLookup, paymentsINPS} from '../../specificUtils/configs'

const useStyles = makeStyles(theme => ({
  boxlighter: {
    background: 'rgb(255, 250, 229)',
    padding: '2%!important',
  },
}))

export default function StepPaymentMethod(props) {
  const {amount, amountDescription, paymentState, onUpdatePayment, onTipo, participation} = props
  const {updatingPayment, updatedPayment, tipo} = paymentState
  const classes = useStyles()
  const handleChange = () => {
    if (tipo === null) {
      onTipo('sicuro')
    }
    onUpdatePayment()
  }

  const isPaymentMethodVisible = updatedPayment
  /*Conferma la prenotazione e mantieni il tuo posto anche in caso di mancata vincita del contributo Inps,
        versando subito l'acconto di € {amount / 100} che verrà scalato dalla quota totale.
        Conferma la prenotazione versando l’acconto di € {amount / 100} che verrà scalato dalla quota totale.*/
  // let typografyIntro = <Typography variant="h5">Seleziona il tasto <strong>"Effettua il pagamento"</strong> per
  //   decidere come versare l'acconto di € {amount / 100}
  //   che verrà scalato dalla quota totale. Potrai scegliere: se effettuare
  //   il pagamento tramite bonifico bancario entro 5 giorni dall'invio della
  //   presente prenotazione; <br />
  //   oppure se pagare ora tramite pagamento online.
  //   <br />
  //   <br />
  // </Typography>;
  return (
    <React.Fragment>
      <Grid item xs={12} sm={1}></Grid>
      <Grid
        item
        xs={12}
        sm={10}
        className={classes.boxlighter}
        align-items="center">
        <Typography variant="subtitle2">
          {amountDescription}
        </Typography>
        
        <Button
          variant="contained"
          color="secondary"
          onClick={handleChange}
          disabled={isPaymentMethodVisible || updatingPayment}>
          Concludi la prenotazione
        </Button>
      </Grid>
      <Grid item xs={12} sm={1}></Grid>
      {isPaymentMethodVisible && <Payment amount={amount} />}
    </React.Fragment>
  )
}
