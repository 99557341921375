import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  gridItemFlex: {
    display: 'flex',
  },
  gridItemFlexCenter: {
    justifyContent: 'center',
  },
  label: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  loginButton: {
    backgroundColor: '#FC7200',
    color: 'white',
    marginRight: theme.spacing(1),
    padding: `0 ${theme.spacing(8)}px`,
    '&:hover': {
      backgroundColor: '#FC7200',
      color: 'white',
    },
  },
  mediumHorizontalSpace: {
    width: theme.spacing(1),
  },
  smallHorizontalSpace: {
    width: theme.spacing(0.5),
  },
  textAction: {
    color: '#FC7200',
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  textItalic: {
    fontStyle: 'italic',
  },
  textUnderline: {
    textDecoration: 'underline',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

function MembershipActionFooter({
  captionText,
  onPrimaryAction,
  onSecondaryAction,
  isPrimaryActionDisabled,
  primaryActionText,
  secondaryActionText,
  isPerformingPrimaryAction = false,
}) {
  const classes = useStyles()

  //Per evitare di creare regressioni, indico che l'azione non dovrà essere mostrata solo se tutti e tre i valori sono settati.
  //Questo perché nella form di login è stata imposta questa condizione per nascondere la registrazione
  const showSecondAction = (captionText && onSecondaryAction && secondaryActionText);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid
        className={`${classes.gridItemFlex} ${classes.gridItemFlexCenter}`}
        item
        xs={12}>
        <Button
          className={classes.loginButton}
          disabled={isPrimaryActionDisabled}
          onClick={onPrimaryAction}>
          {primaryActionText}
          {isPerformingPrimaryAction && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </Grid>
      {showSecondAction && <Grid
          className={`${classes.gridItemFlex} ${classes.gridItemFlexCenter}`}
          item
          xs={12}>
        <Typography
            className={`${classes.label} ${classes.textItalic}`}
            variant="caption">
          {captionText}
        </Typography>
        <div className={classes.mediumHorizontalSpace}/>
        <ButtonBase onClick={onSecondaryAction}>
          <Typography
              className={`${classes.label} ${classes.textAction} ${classes.textBold} ${classes.textUnderline}`}
              variant="caption">
            {secondaryActionText}
          </Typography>
          <div className={classes.smallHorizontalSpace}/>
          <Typography
              className={`${classes.textAction} ${classes.textBold}`}
              variant="caption">
            &rarr;
          </Typography>
        </ButtonBase>
      </Grid>}
    </Grid>
  )
}

MembershipActionFooter.propTypes = {
  captionText: PropTypes.string,
  onPrimaryAction: PropTypes.func.isRequired,
  onSecondaryAction: PropTypes.func,
  isPrimaryActionDisabled: PropTypes.bool.isRequired,
  primaryActionText: PropTypes.string.isRequired,
  secondaryActionText: PropTypes.string,
  isPerformingPrimaryAction: PropTypes.bool,
}

export default MembershipActionFooter
