import React from 'react'
// import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'

export default function Asynchronous(props) {
  const {onGetOptions, onChange, placeholder} = props
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  // const loading = open && options.length === 0

  // React.useEffect(() => {
  //   let active = true

  //   if (!loading) {
  //     return undefined;
  //   }

  //   (async () => {
  //     const response = await fetch('https://country.register.gov.uk/records.json?page-size=5000');
  //     await sleep(1e3); // For demo purposes.
  //     const countries = await response.json();

  //     if (active) {
  //       setOptions(Object.keys(countries).map(key => countries[key].item[0]));
  //     }
  //   })();

  //   return () => {
  //     active = false;
  //   };
  // }, [loading])

  React.useEffect(() => {
    let active = true
    async function getOptions(toSearch) {
      try {
        setLoading(true)
        const result = await onGetOptions(toSearch)
        if (active) {
          setOptions(result)
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        if (active) {
          setOptions([])
          setLoading(false)
        }
      }
    }
    if (!inputValue || inputValue.length < 2) {
      return undefined
    }
    getOptions(inputValue)
    return () => {
      active = false
      setLoading(false)
    }
  }, [inputValue, onGetOptions])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <Autocomplete
      // style={{width: 300}}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onChange={(_, value) => {
        onChange(value)
      }}
      onInputChange={(_, value) => {
        setInputValue(value)
      }}
      value={props.value}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => option.name}
      options={options}
      loading={loading}
      autoHighlight
      closeText="Chiudi"
      loadingText="Caricando..."
      noOptionsText="Nessun dato"
      openText="Apri"
      renderInput={params => (
        <TextField
          {...params}
          // variant="outlined"
          placeholder={placeholder}
          fullWidth
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
