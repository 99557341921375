export const nationalities = Object.freeze([
  {
    value: 'Italiana',
  },
  {
    value: 'Estera',
  },
])
export const participations = Object.freeze([
  // Il tipo 6 era stato inteso per gestire le pratiche dei partecipanti ai
  // bandi INPS che prenotano nella speranza di essere vincitori.
  // Al 30/04/2021 la richiesta ufficiale è di cambiare ld descrizione del tipo
  // 6 da 'partecipante' a 'vincintore' e inibire la scelta del tipo 2.
  // Questo è un BUG 'informativo', nel senso che si andranno a generare delle
  // pratiche in realtà 'vincitrici' che i flussi implementati considerano
  // 'partecipanti'.
  //
  // {
  //   value: '2',
  //   label: 'Vincitore bando INPSieme 2020',
  // },
  // {
  //   value: '6',
  //   label: 'Partecipante bando INPSieme',
  // },
  // {
  //   value: '4',
  //   label: 'Partecipo come Azienda Convenzionata',
  // },
  {
    value: '2',
    label: 'Partecipante bando INPSieme',
  },
  {
    value: '1',
    label: 'Partecipo privatamente',
  },

  /* {
    value: '4',
    label: 'Partecipo con azienda convenzionata',
  }, */
  // {
  //   value: '5',
  //   label: 'Partecipo tramite portale Welfare',
  // },
])

export const participationsLookup = Object.freeze({
  INPSIEME: '2',
  PRIVATO: '1',
  AZIENDA_CONVENZIONATA: '4',
  INPSNUOVO: '6',
  // WELFARE: '5',
})

export const bonusTypes = Object.freeze({
  AMICO: 'amico',
  FRATELLO: 'fratello',
  GRUPPO: 'gruppi',
  DOPPIA: 'doppia',
})

export const disabilityTypes = Object.freeze({
  CARROZZINA: 'carrozzina',
  MOTORIA: 'motoria',
  ALTRO: 'altro',
})

export const stepsByName = Object.freeze({
  CUSTOMER_DATA: 0,
  BONUS: 1,
  INFORMATION: 2,
  EXTRA_SERVICES: 3,
  RECAP: 4,
})

// @stefano / 04-05-2020: aggiungo i valori di default per il valore dell'acconto
export const aggiungiAccontoForzatoPrice = Object.freeze({
  DEFAULT: 10000, // in millesimi
})

// @stefano / 04-05-2020: aggiungo i valori di default per le scelte che deve fare per l'inps
export const paymentsINPS = Object.freeze({
  TIPO_ACCONTO_SI: 'sicuro',
  TIPO_ACCONTO_NO: 'no_acconto',
})

export const EMPTY_INPS_VALUE = '-1'
