import React from 'react'
import clsx from 'clsx'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  alertBox: {
    marginBottom: '5%',
  },
  blockquote: {
    background: '#fff',
    borderLeft: '5px solid orange',
    padding: '2%',
  },
})

export default function Notice({children}) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.alertBox, 'paper')}>
      <blockquote className={classes.blockquote}>{children}</blockquote>
    </div>
  )
}
