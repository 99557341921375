import React from 'react'
import clsx from 'clsx'
import Recap from './StepPaymentRecap'
// import Payment from 'components/Payment'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import {participationsLookup} from '../../specificUtils/configs'
import StepPaymentPrivate from './StepPaymentPrivate'
// import StepPaymentINPS from './StepPaymentINPS'
import StepPaymentAllowFree from './StepPaymentAllowFree'
import StepPaymentSuccess from './StepPaymentSuccess'
import StepPaymentError from './StepPaymentError'
import ModalAlert from 'components/ModalAlert'

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
}))

export default function StepPayment(props) {
  const {participation, priceError, paymentState} = props
  // @stefano / 04-05-2020: setto appState e setto paymentState

  const {
    confirmed,
    paid,
    updatePaymentError,
    confirmError,
    payError,
    hasExtraPaymentServices,
  } = paymentState
  const hasError = !!updatePaymentError || !!confirmError || !!payError || !!priceError
  const classes = useStyles()
  let errorTitle = 'Errore'
  let errorMessage = 'Errore generico.'
  if (hasError) {
    if (updatePaymentError) {
      errorTitle = "Errore nell'aggiornamento delle preferenze"
      errorMessage =
        updatePaymentError.message || 'Impossibile salvare la preferenza'
    } else if (priceError) {
      errorTitle = 'Errore nel calcolo del prezzo'
      errorMessage = 'Impossibile proseguire'
    } else if (confirmError) {
      errorTitle = 'Errore nella conferma della pronotazione'
      errorMessage =
        confirmError.message || 'Impossibile confermare la prenotazione'
    } else if (payError) {
      errorTitle = 'Errore nel pagamento'
      errorMessage =
        payError.message || "Impossibile completare l'operazione di pagamento"
    }
  }
  
  // 2023-01-27 Rimosso il controllo `hasExtraPaymentServices` per questa richiesta
  // da parte di giocamondo:
  //   flusso INPS step 5 > far comparire la domanda (vuoi partire anche da pvt se 
  //   non vinci pagando acconto?) a prescindere dalla modalità di pagamento
  //   selezionata allo step 4
  const StepPaymentComponent = (participation === participationsLookup.INPSNUOVO || participation === participationsLookup.INPSIEME)
      ? StepPaymentAllowFree
      : StepPaymentPrivate

  return (
    <div>
      {/**
       * <Notice division={appState.division}/>
       */}
      {/*TODO: verificare se da rimuovere o modificare {participation === participationsLookup.INPSIEME && <Notice division={appState.division}/>} */}
      {hasError && (
        <ModalAlert
          title={errorTitle}
          message={errorMessage}
          isOpen={true}
          error
        />
      )}
      {!confirmed && !paid && !hasError ? (
        <Grid
          container
          justify="center"
          className={clsx('content-summary', classes.marginBottom)}>
          
          <Grid item xs={12} alignContent="center" className="paystep">
            <h4 class="text-center">Sotto questo riepilogo potrai concludere la tua prenotazione</h4>
            <Recap />
          </Grid>

          <Grid item xs={12} alignContent="center">
            <StepPaymentComponent {...props} />
          </Grid>
        </Grid>
      ) : (
        <React.Fragment>
          {!!confirmed || !!paid ? (
            <StepPaymentSuccess />
          ) : hasError ? (
            <StepPaymentError title={errorTitle} message={errorMessage} />
          ) : null}
          <Recap />
        </React.Fragment>
      )}
    </div>
  )
}
