import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {bonusTypes, EMPTY_INPS_VALUE, participationsLookup,} from '../../specificUtils/configs'
import Coupon from './Coupon'
import Voucher from './Voucher'
import {MenuItem} from '@material-ui/core'
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {AppContext} from "../../../contexts/AppContext";

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    textField4: {
        width: '32.333333%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    textField6: {
        width: '46.666%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    textField12: {
        width: '96%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    menu: {
        width: 200,
    },
}))

export default function StepBonusInpsieme(props) {
    const [participationOk, setParticipationOk] = useState();
    const {
        division,
        inpsServices,
        participations,
        step1ChildFiscalCode,
        step2Participation,
        step2InpsServiceId,
        step2Grade,
        step2Class,
        step2InpsServiceFlag,
        step2bonuses,
        step2BonusRequested,
        step2BonusSurname,
        step2BonusName,
        onEventChange,
        onSimpleCheckboxChange,
        onBonusRequest,
        step2Coupon,
        step2VoucherCode,
        step2INPSnewQuestion,
    } = props
    const errors = props.errors || {}
    const classes = useStyles()

    const handleFlagChange = label => event => {
        const {value} = event.target
        setParticipationOk(value)
    }



    return (
        <React.Fragment>
            <div className="box-light boxed">
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">
                        Hai intenzione di partecipare al Bando Estate Inpsieme?
                    </FormLabel>
                    <RadioGroup
                        name="inpsieme"
                        value={participationOk}
                        onChange={handleFlagChange('participationOk')}>
                        <FormControlLabel value="sì" control={<Radio/>} label="Sì"/>
                        <FormControlLabel value="no" control={<Radio/>} label="No"/>
                    </RadioGroup>
                </FormControl>

                {(participationOk === 'sì') && <TextField
                    id="inps-contributo"
                    select
                    error={
                        errors && errors.step2InpsServiceId
                            ? 'Indicazione contributo obbligatoria *'
                            : null
                    }
                    className={classes.textField6 + ' select col-xs-12'}
                    value={step2InpsServiceId}
                    onChange={onEventChange('step2InpsServiceId')}
                    SelectProps={{
                        native: true,
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    margin="normal">
                    <option value="">Seleziona ISEE</option>
                    {inpsServices.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>}
            </div>
            <br/>
            <div className="box-light boxed">
                <Grid container spacing={3} className="panel">
                    <Grid item xs={12} sm={3} className="bonus-wrapper">
                        <Typography variant="h6">Bonus</Typography>
                        {/*<IconGift color="secondary" />*/}
                        <img
                            src="https://t-ec.bstatic.com/static/img/genius/genius_trial/tier-gift/1c8d6deaaabcdc179a20aefe503b0374b6ff096a.png"
                            height={100}
                            alt="bonus"
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        {step2bonuses.filter(
                            ({bonustype}) => bonustype === bonusTypes.FRATELLO
                        ).length > 0 ? (
                            <React.Fragment>
                                <div className="light-box">
                                    <Typography variant="h5">
                                        Inserisci il nominativo del fratello/sorella che partirà per
                                        un soggiorno Giocamondo
                                        {/* {division === '1' ? ' Giocamondo Study ' : ' Giocamondo '} */}
                                        {/* nel 2020 */}
                                    </Typography>
                                    <p>
                                        Lo sconto <strong>Bonus Fratelli</strong> verrà applicato su
                                        entrambe le pratiche in cui verrà selezionato.
                                    </p>
                                    <br/>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={step2BonusRequested}
                                                onChange={onBonusRequest(bonusTypes.FRATELLO)}
                                            />
                                        }
                                        label="Voglio usufruire del Bonus Fratelli"
                                    />
                                    <br/>
                                    <TextField
                                        id="cognome"
                                        label="Cognome"
                                        className={classes.textField}
                                        value={step2BonusSurname}
                                        onChange={onEventChange('step2BonusSurname')}
                                        margin="normal"
                                    />
                                    <TextField
                                        id="nome"
                                        label="Nome"
                                        className={classes.textField}
                                        value={step2BonusName}
                                        onChange={onEventChange('step2BonusName')}
                                        margin="normal"
                                    />
                                </div>
                            </React.Fragment>
                        ) : (
                            'Nessun bonus disponibile su questo pacchetto, clicca avanti per proseguire.'
                        )}
                    </Grid>
                </Grid>
            </div>
            <br/>
            <Coupon onChange={onEventChange('step2Coupon')} value={step2Coupon}/>
            <Voucher
                step1ChildFiscalCode={step1ChildFiscalCode}
                onChange={onEventChange}
                value={step2VoucherCode}
            />
        </React.Fragment>
    )
}

StepBonusInpsieme.propTypes = {
    inpsServices: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    participations: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
    step2bonuses: PropTypes.arrayOf(
        PropTypes.shape({
            bonustype: PropTypes.string.isRequired,
        })
    ),
    step2Participation: PropTypes.string,
    step2InpsServiceId: PropTypes.string,
    step2Grade: PropTypes.string,
    step2Class: PropTypes.string,
    step2InpsServiceFlag: PropTypes.bool,
    step2BonusRequested: PropTypes.bool,
    step2BonusSurname: PropTypes.string,
    step2BonusName: PropTypes.string,
    step2Coupon: PropTypes.string,
    onEventChange: PropTypes.func.isRequired,
    onSimpleCheckboxChange: PropTypes.func.isRequired,
    onBonusRequest: PropTypes.func.isRequired,
}
